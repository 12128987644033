/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useUser from "src/hooks/useUser";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InputMask from "react-input-mask";
import {
  ButtonActive,
  ButtonClosedForm,
  ButtonDefault,
  ButtonInactive,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";

import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import { iUser } from "src/interfaces/user";
import { maskForManyTypesString } from "src/functions/text";
import useCompany from "src/hooks/useCompany";
import Empty from "src/components/Empty";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import usePasswordRecovery from "src/hooks/usePasswordRecovery";
import { MaskStyle } from "src/styles/globalStyle";

const UserComponent: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { companySelected } = useCompany();
  const { sendMail } = usePasswordRecovery();

  const {
    validationErrorEmail,
    validationErrorPassword,
    showPassword,
    handleClickShowPassword,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    active,
    setActive,
    main,
    setMain,
    username,
    setUsername,
    phone,
    setPhone,
    roles,
    role,
    setRole,
    handleSalveNewUser,
    loading,
    toFillUSerDataOnForm,
    visibleRowsTableUSer,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    getUsersOfCompany,
    dataUserSelect,
    handleUpdateUser,
    handleDeleteUser,
    getRoles,
  } = useUser();

  const header = [
    {
      label: translations[param]["name"],
    },
    {
      label: translations[param]["phone"],
    },
    {
      label: translations[param]["email"],
    },
    {
      label: translations[param]["active"],
    },
    {
      label: translations[param]["main"],
    },
  ];

  useEffect(() => {
    getUsersOfCompany(companySelected?.id);
    getRoles();
  }, []);

  if (loading) return <CircularLoading />;

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!dataUserSelect?.id) {
              handleSalveNewUser();
            } else {
              handleUpdateUser();
            }
          }}
        >
          <Grid container spacing={1}>
            <Grid xs={12} md={12} lg={10}>
              <FormLabel>{translations[param]["name"]}: </FormLabel>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  name="name"
                  label={translations[param]["name"]}
                  required
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid
              md={12}
              lg={2}
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      disabled={dataUserSelect?.id ? true : false}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="main"
                      name="main"
                      size="small"
                      checked={main}
                      onChange={(e) => setMain(e.target.checked)}
                    />
                  }
                  label={translations[param]["main"]}
                />
              </FormControl>
            </Grid>

            <Grid xs={12} md={12} lg={4}>
              <FormLabel>{translations[param]["user"]}: </FormLabel>
              <TextField
                id="username"
                fullWidth
                name="username"
                label={translations[param]["user"]}
                required
                variant="outlined"
                size="small"
                disabled={dataUserSelect?.id ? true : false}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid xs={12} md={12} lg={4}>
              <FormLabel>{translations[param]["email"]}: </FormLabel>
              <TextField
                id="email"
                name="email"
                label={translations[param]["email"]}
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                size="small"
                error={validationErrorEmail}
                helperText={
                  validationErrorEmail
                    ? translations[param]["oops_check_if_your_email_is_correct"]
                    : ""
                }
              />
            </Grid>
            <Grid xs={12} md={12} lg={4}>
              <FormLabel>{translations[param]["password"]}: </FormLabel>
              <FormControl fullWidth>
                <OutlinedInput
                  id="password"
                  name="password"
                  size="small"
                  required
                  sx={dataUserSelect?.id ? { background: "#ececec" } : null}
                  disabled={!!dataUserSelect?.id}
                  value={dataUserSelect?.id ? "*****" : password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  error={validationErrorPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        disabled={!!dataUserSelect?.id}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {validationErrorPassword && (
                  <FormHelperText error={validationErrorPassword}>
                    {translations[param]["invalid_password_message"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid xs={12} md={12} lg={4}>
              <FormLabel>{translations[param]["phone"]}: </FormLabel>
              <MaskStyle>
                <InputMask
                  mask="(99) 99999-9999"
                  name="phone"
                  placeholder={translations[param]["phone"]}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </MaskStyle>
            </Grid>

            <Grid xs={12} md={12} lg={4}>
              <FormLabel>Perfil: </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                size="small"
                fullWidth
                label="Perfil"
                onChange={(event: SelectChangeEvent) => {
                  setRole(event.target.value as string);
                }}
              >
                <MenuItem value="select" disabled>
                  Selecione:{" "}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {roles.map((rl: any) => (
                  <MenuItem key={rl.id} value={rl.id}>
                    {rl.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              xs={12}
              md={12}
              lg={4}
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <ButtonClosedForm
                label={translations[param]["clear_form"]}
                onClick={() => toFillUSerDataOnForm(null)}
              />
              <FormControl>
                {dataUserSelect?.id ? (
                  <ButtonSalveForm
                    label={translations[param]["update"]}
                    type="submit"
                  />
                ) : (
                  <ButtonInclude
                    label={translations[param]["include"]}
                    type="submit"
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {visibleRowsTableUSer.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {visibleRowsTableUSer?.map((data: iUser) => (
                <TableRow
                  hover
                  key={data.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => toFillUSerDataOnForm(data)}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {maskForManyTypesString(
                      data?.phone ?? "",
                      "(##) #####-####"
                    )}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.email}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell onClick={() => toFillUSerDataOnForm(data)}>
                    {data.isMain ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    {!data.active ? (
                      <ButtonActive
                        label={translations[param]["activate"]}
                        onClick={() => handleDeleteUser(data)}
                      />
                    ) : (
                      <ButtonInactive
                        label={translations[param]["inactive"]}
                        onClick={() => handleDeleteUser(data)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <ButtonDefault
                      label={translations[param]["change_password"]}
                      onClick={() =>
                        sendMail({
                          username: `${data.email}`,
                        })
                      }
                      fontSize="10px"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default UserComponent;
