/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import CircularLoading from "src/components/Loading";
import useCompany from "src/hooks/useCompany";
import InputAdornment from "@mui/material/InputAdornment";

function Financial() {
  const {
    loading,
    paymentConditions,
    getPaymentConditionsAdministrator,
    condicaoPagamentoS4M,
    setCondicaoPagamentoS4M,
    taxaJurosS4M,
    setTaxaJurosS4M,
    multaS4M,
    setMultaS4M,
    handleUpdatedUser,
  } = useCompany();

  useEffect(() => {
    getPaymentConditionsAdministrator();
  }, []);

  if (loading) return <CircularLoading />;

  return (
    <Card sx={{ width: "100%", padding: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TextField
            label="Juros"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            value={taxaJurosS4M}
            onChange={(e) => setTaxaJurosS4M(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TextField
            label="Multa"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            value={multaS4M}
            onChange={(e) => setMultaS4M(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Select
            fullWidth
            size="small"
            value={condicaoPagamentoS4M}
            variant="outlined"
            onChange={(event: SelectChangeEvent) =>
              setCondicaoPagamentoS4M(event.target.value as string)
            }
          >
            <MenuItem value="select" disabled>
              Selecione...
            </MenuItem>
            <MenuItem value="">...</MenuItem>

            {paymentConditions.map((item: any) => (
              <MenuItem value={`${item.id}`} key={item.id}>
                {item.descricao}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleUpdatedUser}
          startIcon={<span>&#10003;</span>} // Ícone ✓
        >
          Salvar
        </Button>
      </Box>
    </Card>
  );
}

export default Financial;
