/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { theme } from "src/theme";
import useCompany from "src/hooks/useCompany";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CircularLoading from "src/components/Loading";

const AdditionalInformations: React.FC = () => {
  const {
    handleAddSection,
    handleRemoveSection,
    informacoesAdicionais,
    empresasClassificacaoTipo,
    handleInputChange,
    empresasClassificacao,
    getEmpresaClassificacao,
    setEmpresasClassificacao,
    loadingEmpresaClassificacao,
  } = useCompany();

  const addType = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idTipo: value?.id ?? "",
            descricaoTipo: value?.descricao ?? "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  const removeType = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idTipo: "",
            descricaoTipo: "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };
  const addClassificacao = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idClassificacao: value?.id ?? "",
            descricaoClassificacao: value?.descricao ?? "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  const removeClassificacao = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            idClassificacao: "",
            descricaoClassificacao: "",
          },
        },
      },
      index,
      "informacoesAdicionais"
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Informações Adicionais</Typography>
        <IconButton
          color="primary"
          onClick={() => {
            handleAddSection("informacoesAdicionais");
            setEmpresasClassificacao([]);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <br />
      <Grid container spacing={2}>
        {informacoesAdicionais?.map((section: any, index: any) => (
          <Box
            key={index}
            marginBottom={theme.spacing(2)}
            display="flex"
            sx={{ width: "100%" }}
          >
            <Grid container spacing={2} wrap="nowrap" sx={{ width: "100%" }}>
              {[
                { label: "Tipo", name: "tipo" },
                { label: "Classificação", name: "classificacao" },
                { label: "Informação", name: "valor" },
              ]?.map((field, idx) => {
                return (
                  <Grid xs={12} key={idx} sx={{ width: "100%" }}>
                    {field.name === "tipo" && (
                      <Autocomplete
                        disablePortal
                        options={empresasClassificacaoTipo}
                        freeSolo
                        fullWidth
                        id="show_select_administrator_code"
                        getOptionLabel={(option: any) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option?.descricao || "";
                        }}
                        value={
                          informacoesAdicionais[index]?.idTipo
                            ? empresasClassificacaoTipo.find(
                                (item: any) =>
                                  item.id ===
                                  informacoesAdicionais[index]?.idTipo
                              ) ||
                              informacoesAdicionais[
                                index
                              ]?.descricaoTipo.toString()
                            : null
                        }
                        size="small"
                        onChange={(_, value) => {
                          if (value) {
                            getEmpresaClassificacao({ typeId: value.id });
                            addType(index, value);
                          } else {
                            removeType(index);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Tipo" size="small" />
                        )}
                      />
                    )}
                    {field.name === "classificacao" && (
                      <Autocomplete
                        disablePortal
                        options={empresasClassificacao}
                        freeSolo
                        fullWidth
                        id="show_select_administrator_code"
                        getOptionLabel={(option: any) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option?.descricao || "";
                        }}
                        value={
                          informacoesAdicionais[index]?.idClassificacao
                            ? empresasClassificacao.find(
                                (item: any) =>
                                  item.id ===
                                  informacoesAdicionais[index]?.iddClassificacao
                              ) ||
                              informacoesAdicionais[index]
                                ?.descricaoClassificacao
                            : null
                        }
                        size="small"
                        onChange={(_, value) => {
                          if (value) {
                            addClassificacao(index, value);
                          } else {
                            removeClassificacao(index);
                          }
                        }}
                        renderInput={(params) =>
                          loadingEmpresaClassificacao ? (
                            <CircularLoading />
                          ) : (
                            <TextField
                              {...params}
                              label="Classificação"
                              size="small"
                            />
                          )
                        }
                      />
                    )}
                    {field.name === "valor" && (
                      <TextField
                        placeholder={field.label}
                        fullWidth
                        size="small"
                        name={field.name}
                        label={field.label}
                        value={section[field.name]}
                        onChange={(e) =>
                          handleInputChange(e, index, "informacoesAdicionais")
                        }
                      />
                    )}
                  </Grid>
                );
              })}
              <IconButton
                onClick={() =>
                  handleRemoveSection(index, "informacoesAdicionais", section)
                }
                style={{ marginTop: theme.spacing(1) }}
              >
                <RemoveCircleOutlineIcon color="error" />
              </IconButton>
            </Grid>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default AdditionalInformations;
