/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "src/components/Layout";
import MenuTabs from "src/components/MenuTabs";
import { iContentTabs, iTabs } from "src/interfaces/iTabs";
import UserComponent from "../components/UserComponent";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import GeneralDates from "../components/GeneralDates";
import ProductCategoryComponent from "../components/ProductCategory";
import Product from "../components/Product";
import PaymentCondition from "../components/PaymentCondition";
import Address from "../components/Address";
import useCompany from "src/hooks/useCompany";
import Contacts from "../components/Contacts";
import Documents from "../components/Documents";
import { ButtonClosedForm } from "src/components/ButtonsCommons";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import Financial from "../components/Financial";

const Register: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const {
    companySelected,
    activeTabsMenu,
    handleSelectedCompanyChecked,
    setValue,
    setCleanFilters,
    page,
    setPage,
    setCnpjSearch,
    setSocialNameSearch,
    setNameSearch,
    setFilterType,
    setFilterActive,
    setBuyer,
    setSupplier,
  } = useCompany();

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, []);

  const tabs: Array<iTabs> = [
    {
      label: translations[param]["general_data"],
    },
    {
      label: translations[param]["addresses"],
    },
    {
      label: translations[param]["contacts"],
    },
    {
      label: translations[param]["users"],
    },
    {
      label: translations[param]["payment_conditions"],
    },
    {
      label: translations[param]["products"],
    },
    {
      label: translations[param]["product_category"],
    },
    {
      label: translations[param]["documents"],
    },
    {
      label: "Financeiro",
    },
  ];

  const lengthNameCompany = 32;

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
      href: `/${lang}/settings/companys`,
    },
    {
      name: translations[param]["companys"],
      href: `/${lang}/settings/companys`,
    },
    {
      name: companySelected?.name
        ? companySelected?.name.length >= lengthNameCompany
          ? `${companySelected?.name.substring(0, lengthNameCompany)} ...`
          : companySelected?.name
        : translations[param]["include"],
    },
  ];

  const contentTabs: Array<iContentTabs> = [
    {
      value: <GeneralDates />,
    },
    {
      value: <Address />,
    },
    {
      value: <Contacts />,
    },
    {
      value: <UserComponent />,
    },
    {
      value: <PaymentCondition />,
    },
    {
      value: <Product />,
    },
    {
      value: <ProductCategoryComponent />,
    },
    {
      value: <Documents />,
    },
    {
      value: <Financial />,
    },
  ];

  const buttons = [
    <ButtonClosedForm
      label="Fechar"
      onClick={() => {
        handleSelectedCompanyChecked(null);
        router(-1);
        setCleanFilters(false);
      }}
    />,
  ];

  return (
    <Layout
      breadcrumbs={breadcrumb}
      buttons={buttons}
      callMenu={() => {
        setCnpjSearch("");
        setSocialNameSearch("");
        setNameSearch("");
        setFilterType("select");
        setFilterActive("select");
        setBuyer(false);
        setSupplier(false);
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <MenuTabs
        tabs={tabs}
        contentTabs={contentTabs}
        isDisabled={!companySelected?.id ? activeTabsMenu : false}
      />
    </Layout>
  );
};

export default Register;
