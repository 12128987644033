/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iCompanyContact,
  iCompanyContactContextProps,
  iCompanyContactPage,
} from "src/interfaces/companyContact";
import CompanyContact from "src/models/CompanyContact";
import companyContactConsumer from "src/services/companyContact";
import { toast } from "react-toastify";
import Login from "src/models/Login";
import areaConsumer from "src/services/area";
import { iPage } from "src/interfaces/layout";
import companyConsumer from "src/services/company";

const CompanyContactContext = createContext<iCompanyContactContextProps>(
  {} as iCompanyContactContextProps
);

export function CompanyContactProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyContactSelected, setCompanyContactSelected] =
    useState<iCompanyContact | null>(null);
  const [areas, setAreas] = useState<any[]>([]);
  const [companyContacts, setCompanyContacts] = useState<
    Array<iCompanyContact>
  >([]);
  const [validationErrorEmail, setValidationErrorEmail] =
    useState<boolean>(false);
  const [page, setPage] = useState<iCompanyContactPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });
  const [pageType, setPageType] = useState<iPage>({
    page: 0,
    rowsPerPage: 100,
    total: 0,
  });

  const [redesSociais, setRedesSociais] = useState<any[]>([]);
  const [redesSociaisToSearch, setRedesSociaisToSearch] = useState<any[]>([]);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [cell, setCell] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [office, setOffice] = useState<string>("");
  const [representative, setRepresentative] = useState<boolean>(false);
  const [main, setMain] = useState<boolean>(false);
  const [area, setArea] = useState<string>("select");
  const [typeId, setTypeId] = useState<string>("select");
  const [types, setTypes] = useState<any>([]);

  const [pageRedesSociais, setPageRedesSociais] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [companysContactToSearch, setCompanysContactToSearch] = useState<
    Array<any>
  >([]);
  const [companysContactToSearchSupplier, setCompanysContactToSearchSupplier] =
    useState<Array<any>>([]);
  const [companysContactToSearchBuyer, setCompanysContactToSearchBuyer] =
    useState<Array<any>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getCompanysContacts = async (companyId: string, company?: any) => {
    setLoading(true);

    try {
      const response = await companyContactConsumer.get(page, companyId);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        CompanyContact.adapterToClass(item)
      );

      if (response.data.totalItems > 0)
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });

      setCompanysContactToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item.id,
            label: item.nome,
          };
        })
      );

      if (companysContactToSearchBuyer.length === 0 && company?.buyer) {
        setCompanysContactToSearchBuyer(
          response.data.items.map((item: any) => {
            return {
              id: item.id,
              label: item.nome,
            };
          })
        );
      }

      if (company?.supplier) {
        setCompanysContactToSearchSupplier(
          response.data.items.map((item: any) => {
            return {
              id: item.id,
              label: item.nome,
            };
          })
        );
      }

      setCompanyContacts(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as redes cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (data: iCompanyContact | null) => {
    setCompanyContactSelected(data);

    setName(data?.name ?? "");
    setCpf(data?.cpf ?? "");
    setEmail(data?.email?.toLowerCase() ?? "");
    setPhone(data?.phone ?? "");
    setOffice(data?.office ?? "");
    setRepresentative(data?.representative ?? false);
    setArea(data?.areaId ?? "select");
    setMain(data?.main ?? false);
    setCell(data?.cell ?? "");
    setTypeId(data?.typeId ?? "select");

    if (data?.redesSociais && data?.redesSociais?.length > 0) {
      setRedesSociais(
        data?.redesSociais?.map((item: any) => {
          return {
            ...item,
            valor: item.link,
            descricao: item.redesSociaisDescricao,
          };
        })
      );
    } else {
      setRedesSociais([]);
    }
  };

  const handleSalve = async (companyId: string) => {
    if (!area || area === "select") {
      return toast.warning("O campo área é obrigatório!");
    }

    if (!typeId || typeId === "select") {
      return toast.warning("O campo Tipo é obrigatório!");
    }

    try {
      setValidationErrorEmail(false);

      setLoading(true);

      const contact = new CompanyContact(
        null,
        name,
        email.toLowerCase(),
        phone,
        office,
        representative,
        cpf,
        companyId,
        area,
        main,
        cell,
        typeId
      );

      const response = await companyContactConsumer.created(contact);

      if (response.status !== 200 && response.status !== 201) throw response;

      if (redesSociais.length > 0 && response.data.id) {
        sendRedesSociais(response.data.id);
      }

      toast.success("Novo contato cadastrado com sucesso!");
      getCompanysContacts(companyId);
    } catch (e: any) {
      toast.error(
        e?.response?.data ??
          "Ops... tivemos um problema ao cadastrar o novo contato!"
      );
    } finally {
      setLoading(false);
    }
  };

  function formatToCellPhone(number: string) {
    let cleanedNumber = number.replace(/\D/g, "");

    if (cleanedNumber.length === 10) {
      const ddd = cleanedNumber.slice(0, 2);
      const prefix = cleanedNumber.slice(2, 6);

      const knownCellPrefixes = ["7", "8", "9"];

      if (knownCellPrefixes.includes(prefix[0])) {
        return `${ddd}9${cleanedNumber.slice(2)}`;
      }
    }

    if (cleanedNumber.length === 11 && cleanedNumber[2] === "9") {
      return cleanedNumber;
    }

    return number;
  }

  const handleSalveApi = async (
    companyId: string,
    bodyArrayData: any,
    areas?: any[]
  ) => {
    const phones = bodyArrayData.filter((item: any) => item.ddd && item);
    const contacts = bodyArrayData.filter((item: any) => item.nome && item);

    const areaId =
      areas?.find(
        (e: any) => e.descricao.toUpperCase() === "Sócios".toUpperCase()
      )?.id ?? "";

    let latsPhone = "";

    try {
      setValidationErrorEmail(false);

      setLoading(true);

      return Promise.all(
        contacts.map(async (item: any, index: number) => {
          if (index === 0 && phones.length !== 0) {
            latsPhone = `${phones[0].ddd}${phones[0].numero}`;
          } else if (phones[index] !== undefined) {
            latsPhone = `${phones[index].ddd}${phones[index].numero}`;
          }

          const contact = new CompanyContact(
            null,
            item.nome,
            email.toLowerCase(),
            formatToCellPhone(latsPhone),
            CompanyContact.numberToString(item.qualificacao) ?? "Não informado",
            representative,
            cpf,
            companyId,
            areaId,
            main,
            cell,
            typeId
          );

          const response = await companyContactConsumer.created(contact);

          if (response.status !== 200 && response.status !== 201)
            throw response;
        })
      )
        .then(() => {
          toast.success("Novo contato cadastrado com sucesso!");
          handleSelect(null);
          getCompanysContacts(companyId);
        })
        .catch((e) =>
          toast.error(
            e?.response?.data?.message ??
              "Ops... tivemos um problema ao cadastrar o novo contato!"
          )
        );
    } catch (e: any) {
      toast.error(
        e?.data?.message ??
          "Ops... tivemos um problema ao cadastrar o novo contato!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (companyId: string) => {
    if (!area || area === "select") {
      return toast.warning("O campo área é obrigatório!");
    }

    if (!typeId || typeId === "select") {
      return toast.warning("O campo Tipo é obrigatório!");
    }

    try {
      setValidationErrorEmail(false);

      if (!Login.verifyEmail(email.toLowerCase()))
        return setValidationErrorEmail(true);

      setLoading(true);

      const contact = new CompanyContact(
        companyContactSelected?.id ?? "",
        name,
        email.toLowerCase(),
        phone,
        office,
        representative,
        cpf,
        companyId,
        area,
        main,
        cell,
        typeId
      );

      const response = await companyContactConsumer.updated(contact);

      if (response.status !== 200 && response.status !== 201) throw response;

      if (redesSociais.length > 0 && companyContactSelected?.id) {
        sendRedesSociais(companyContactSelected.id);
      }

      toast.success("Contato alterado com sucesso!");
      getCompanysContacts(companyId);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o contato!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (contact: iCompanyContact) => {
    try {
      setLoading(true);

      const response = await companyContactConsumer.deleted(`${contact?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Contato deletada com sucesso!");

      handleSelect(null);
      getCompanysContacts(contact?.companyId ?? "");
    } catch {
      toast.error("Erro ao deletar o contato!");
    } finally {
      setLoading(false);
    }
  };

  const getAreas = async () => {
    setLoading(false);

    try {
      const response = await areaConsumer.get(page);

      if (response.status !== 200) throw response;

      setAreas(response.data.items);
    } catch {
      toast.warning("Erro ao buscar as áreas cadastradas");
    } finally {
      setLoading(false);
    }
  };

  const getTypes = async () => {
    setLoading(true);
    try {
      const response = await companyContactConsumer.getType(pageType);

      if (response.status !== 200) throw response;

      setTypes(response.data.items);

      return response.data.items;
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Erro ao buscar os tipos!");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: any, index: number, section: string) => {
    let { name, value } = event.target;

    // Atualiza todos os campos de uma vez
    if (name === "multiChange") {
      setRedesSociais((prev: any) =>
        prev.map((item: any, idx: any) =>
          idx === index ? { ...item, ...value } : item
        )
      );
    } else {
      const updateSections = (sections: any[]) => {
        const updatedSections = [...sections];
        updatedSections[index] = { ...updatedSections[index], [name]: value };
        return updatedSections;
      };

      setRedesSociais(updateSections(redesSociais));
    }
  };

  const handleAddSection = (section: string) => {
    if (section === "redesSociais") {
      setRedesSociais([...redesSociais, {}]);
    }
  };

  const handleRemoveSection = async (
    index: number,
    section: string,
    item?: { id?: string }
  ) => {
    if (item?.id) {
      try {
        const response = await companyContactConsumer.deletedRedesSociais(
          item.id
        );

        if (response.status !== 200) throw response;

        toast.success("Rede social removida!");
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message ??
            "Ocorreu um erro ao deletar a rede social"
        );
      }
    }

    if (section === "redesSociais") {
      setRedesSociais((prev) =>
        prev.filter((currentItem: any, i) =>
          item?.id ? currentItem?.id !== item.id : i !== index
        )
      );
    }
  };

  const getRedesSociais = async () => {
    setLoading(true);

    try {
      const response = await companyConsumer.redesSociais(pageRedesSociais);

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPageRedesSociais({
          ...pageRedesSociais,
          total: response.data.totalItems,
        });
      }

      setRedesSociaisToSearch(response.data.items);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const sendRedesSociais = async (contatosEmpresaId: string) => {
    setLoading(true);

    try {
      const response = await companyContactConsumer.postRedesSociais({
        contatosEmpresaRedesSociais: redesSociais?.map((item: any) => {
          const data: any = {
            contatosEmpresaId: contatosEmpresaId,
            redesSociaisId: item.redesSociaisId,
            link: item.valor,
          };

          if (item?.id) data["id"] = item.id;

          return data;
        }),
      });

      if (response.status !== 200) throw response;

      toast.success("Redes Sociais Salvas com Sucesso!");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getCompanysContacts,
      loading,
      setLoading,
      companyContacts,
      handleChangePage,
      handleChangeRowsPerPage,
      companyContactSelected,
      setCompanyContactSelected,
      name,
      setName,
      email,
      setEmail,
      phone,
      setPhone,
      office,
      setOffice,
      representative,
      setRepresentative,
      companysContactToSearch,
      setCompanysContactToSearch,
      companysContactToSearchSupplier,
      setCompanysContactToSearchSupplier,
      companysContactToSearchBuyer,
      validationErrorEmail,
      setValidationErrorEmail,
      handleSelect,
      area,
      setArea,
      handleSalve,
      handleSalveApi,
      handleUpdate,
      handleDelete,
      areas,
      getAreas,
      main,
      setMain,
      cpf,
      setCpf,
      cell,
      setCell,
      typeId,
      setTypeId,
      pageType,
      setPageType,
      types,
      setTypes,
      getTypes,
      redesSociais,
      setRedesSociais,
      redesSociaisToSearch,
      setRedesSociaisToSearch,
      handleInputChange,
      handleAddSection,
      handleRemoveSection,
      pageRedesSociais,
      setPageRedesSociais,
      getRedesSociais,
    };
  }, [
    page,
    loading,
    companyContacts,
    name,
    email,
    phone,
    office,
    representative,
    companyContactSelected,
    companysContactToSearch,
    companysContactToSearchSupplier,
    companysContactToSearchBuyer,
    area,
    areas,
    main,
    cpf,
    cell,
    typeId,
    pageType,
    types,
    redesSociais,
    redesSociaisToSearch,
    pageRedesSociais,
  ]);

  return (
    <CompanyContactContext.Provider value={contextValue}>
      {children}
    </CompanyContactContext.Provider>
  );
}

export default CompanyContactContext;
