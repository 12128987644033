import { iCompany } from "src/interfaces/company";

export default class Company implements iCompany {
  id;
  cnpj;
  socialName;
  name;
  active;
  buyer;
  supplier;
  network;
  administrator;
  foreignCompany;
  codeOutdoor;
  numeroLeitos: string;
  site: string;
  dataAtualizacao?: string;
  redesSociais?: any[];
  classificacoes?: any[];
  condicaoPagamentoS4M?: string;
  taxaJurosS4M?: number;
  multaS4M?: number;

  constructor(
    id: string,
    socialName: string,
    name: string,
    cnpj: string,
    active: boolean,
    buyer: boolean,
    supplier: boolean,
    network: string,
    administrator: boolean,
    foreignCompany: boolean,
    codeOutdoor: string,
    numeroLeitos: string,
    site: string,
    dataAtualizacao?: string,
    redesSociais?: any[],
    classificacoes?: any[],
    condicaoPagamentoS4M?: string,
    taxaJurosS4M?: number,
    multaS4M?: number
  ) {
    this.id = id;
    this.socialName = socialName;
    this.name = name;
    this.cnpj = cnpj;
    this.active = active;
    this.buyer = buyer;
    this.supplier = supplier;
    this.network = network;
    this.administrator = administrator;
    this.foreignCompany = foreignCompany;
    this.codeOutdoor = codeOutdoor;
    this.numeroLeitos = numeroLeitos;
    this.site = site;
    this.dataAtualizacao = dataAtualizacao;
    this.redesSociais = redesSociais;
    this.classificacoes = classificacoes;
    this.condicaoPagamentoS4M = condicaoPagamentoS4M;
    this.taxaJurosS4M = taxaJurosS4M;
    this.multaS4M = multaS4M;
  }

  public static isCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj === "") return false;

    if (cnpj.length !== 14) return false;

    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    )
      return false;

    let size: number = cnpj.length - 2;
    let numbers: string = cnpj.substring(0, size);
    let digits: string = cnpj.substring(size);
    let sum: number = 0;
    let pos: number = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;

      if (pos < 2) pos = 9;
    }

    let result: number = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0))) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(size - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
  }

  public static adapterToClass(company: any) {
    return new Company(
      company.id,
      company.razaoSocial,
      company.nome,
      company.cnpj,
      company.ativo,
      company.comprador,
      company.fornecedor,
      company.redeId,
      company.administradora,
      company.empresaEstrangeira,
      company.codigoExterior,
      company.numeroLeitos,
      company.site,
      company.dataAtualizacao ?? "",
      company.redesSociais ?? [],
      company.classificacoes ?? [],
      company.condicaoPagamentoS4M,
      company.taxaJurosS4M,
      company.multaS4M
    );
  }

  public static adapterToJson(company: any) {
    return JSON.parse(company);
  }

  public static adapterToBody(company: iCompany) {
    const data: any = {
      razaoSocial: company.socialName,
      nome: company.name,
      ativo: company.active,
      comprador: company.buyer,
      fornecedor: company.supplier,
      administradora: company.administrator,
      empresaEstrangeira: company.foreignCompany,
    };

    if (
      company.condicaoPagamentoS4M &&
      company.condicaoPagamentoS4M !== "select"
    ) {
      data["condicaoPagamentoS4M"] = company.condicaoPagamentoS4M;
    }

    if (company.taxaJurosS4M) {
      data["taxaJurosS4M"] = company.taxaJurosS4M;
    }

    if (company.multaS4M) {
      data["multaS4M"] = company.multaS4M;
    }

    if (company.cnpj) {
      data["cnpj"] = company.cnpj;
    }

    if (company.dataAtualizacao) {
      data["dataAtualizacao"] = company.dataAtualizacao;
    }

    if (company.site) {
      data["site"] = company.site;
    }

    if (company.numeroLeitos) {
      data["numeroLeitos"] = company.numeroLeitos;
    }

    if (company.codeOutdoor) {
      data["codigoExterior"] = company.codeOutdoor;
    }

    if (company.network && company.network !== "select") {
      data["redeId"] = company.network;
    }

    if (company?.id) {
      return {
        id: company?.id ?? null,
        ...data,
      };
    }

    return {
      ...data,
    };
  }
}
