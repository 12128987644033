/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";
import attributesConsumer from "src/services/Attributes";
import LoginConsumer from "src/services/login";
import ShoppingProcessDemandItemsConsumer from "src/services/shoppingProcessDemandItems";

interface iDemand {
  id?: String;
  quantidade?: number;
  processoCompraId?: String;
  produtoId?: String;
  descricaoDetalhada?: String;
  produtoDescricao?: String;
  produtoCodigo?: String;
  valorReferencia?: string;
  code?: string;
  label?: string;
}

const ShoppingProccessDemandContext = createContext<any>({} as any);

export function ShoppingProccessDemandProvider({
  children,
}: {
  children: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAttributes, setLoadingAttributes] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [demands, setDemands] = useState<Array<any>>([]);
  const [itemsToTrade, setItemsToTrade] = useState<Array<any>>([]);
  const [demand, setDemand] = useState<iDemand | null>(null);
  const [gmvInitialTotal, setGmvInitialTotal] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<any>({ number: 1, order: true });
  const [orderByAttributes, setOrderByAttributes] = useState<any>({
    number: 1,
    order: true,
  });
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [pageAttributes, setPageAttributes] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [attributes, setAttributes] = useState<Array<any>>([]);
  const [attributesSelect, setAttributesSelect] = useState<Array<any>>([]);

  const [descriptionAttributeSearch, setDescriptionAttributeSearch] =
    useState<string>("");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangePageAttrinutes = (_: unknown, newPage: number) => {
    setPage({ ...pageAttributes, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleChangeRowsPerPageAttributes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageAttributes({
      ...pageAttributes,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getDemands = async (
    shoppingProccessId: string,
    rowsPerPages?: number
  ) => {
    try {
      setLoading(true);

      let currentPage = { ...page };

      if (rowsPerPages) {
        currentPage = {
          ...page,
          rowsPerPage: rowsPerPages,
        };
      }

      const response = await ShoppingProcessDemandItemsConsumer.get(
        currentPage,
        shoppingProccessId
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items.map((e: any) => {
        let sum: number = e?.solicitacaoCompras?.reduce(
          (accumulator: number, currentValue: any) =>
            accumulator +
            currentValue.quantidade * currentValue.valorReferencia,
          0
        );

        if (!sum) sum = 0.0;

        return {
          ...e,
          gmvInicial: sum,
        };
      });

      const currentGmvInitialTotal = data?.reduce(
        (acc: number, current: any) => acc + current.gmvInicial,
        0
      );

      setGmvInitialTotal(currentGmvInitialTotal);
      setDemands(data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Ops, tivemos um problema ao buscar as demandas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = async (demand: any) => {
    await getAttributes(demand.produtoId);

    setDemand({
      ...demand,
      code: demand?.produtoCodigo,
      label: demand?.produtoDescricao,
      valorReferencia:
        demand?.valorReferencia?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }) ?? "0,00",
    });

    setAttributesSelect(
      demand?.processoCompraDemandaItensAtributos.map((item: any) => {
        return {
          ...item,
          produtoId: demand.produtoId,
        };
      })
    );
  };

  const saveNewDemand = async (shoppingProcesSelect: any) => {
    setLoading(true);

    try {
      const body = {
        ...demand,
        valorReferencia: demand?.valorReferencia
          ?.replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        processoCompraId: shoppingProcesSelect?.id,
        processoCompraDemandaItensAtributos: attributesSelect,
      };

      const response = await ShoppingProcessDemandItemsConsumer.created(body);

      if (response?.status !== 200) throw response;

      body["id"] = response.data.id ?? "";
      const newDemands = [body, ...demands];
      setDemands(newDemands);

      setDemand(null);
      setIsDialogOpen(false);
      setAttributes([]);
      setAttributesSelect([]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateDemand = async (shoppingProcesSelect: any) => {
    setLoading(true);

    try {
      const body = {
        ...demand,
        valorReferencia: demand?.valorReferencia
          ?.toString()
          ?.replace(/[^0-9]\$/, "")
          .trim()
          .replace(".", "")
          .replace(",", "."),
        processoCompraId: shoppingProcesSelect?.id,
        processoCompraDemandaItensAtributos: attributesSelect,
      };

      const response = await ShoppingProcessDemandItemsConsumer.updated(body);

      if (response?.status !== 200) throw response;

      const demandsWithoutCurrentDemand = demands.filter(
        (e) => e.id !== body.id
      );
      const newDemands = [body, ...demandsWithoutCurrentDemand];
      setDemands(newDemands);

      setDemand(null);
      setIsDialogOpen(false);
      setAttributes([]);
      setAttributesSelect([]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getAttributes = async (productId: string) => {
    setLoadingAttributes(true);

    try {
      const response = await attributesConsumer.get(
        pageAttributes,
        productId,
        orderByAttributes,
        descriptionAttributeSearch.trim()
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems !== 0) {
        setPageAttributes({
          ...pageAttributes,
          total: response.data.totalItems,
        });
      }

      setAttributes(
        response.data.items.filter((item: any, index: number, self: any) => {
          const isFirstOccurrence =
            self.findIndex((obj: any) => obj.descricao === item.descricao) ===
            index;
          return isFirstOccurrence;
        })
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingAttributes(false);
    }
  };

  const handleDelete = async (demandId: string) => {
    setLoading(true);
    try {
      const response = await ShoppingProcessDemandItemsConsumer.delete(
        demandId
      );

      if (response.status !== 200) throw response;

      const newDemands = demands.filter((el: any) => el.id !== demandId);
      setDemands(newDemands);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          "Ops, ocorreu um erro ao deletar a demanda!"
      );
    } finally {
      setLoading(false);
    }
  };

  const requestDemands = async (
    shoppingProces: any,
    participatingCompanies: any[]
  ) => {
    if (participatingCompanies.length === 0) {
      toast.info("Favor informe as empresas participantes!");
      return;
    }

    setLoading(true);

    try {
      const items = [];

      for (const element of participatingCompanies) {
        const data = {
          processoCompraId: shoppingProces.id,
          numeroPC: shoppingProces.number,
          DescricaoPC: shoppingProces.description,
          nomeAdministradorPC: shoppingProces.contactCompanyName,
          dataInicioLevantamento: shoppingProces.dataInicioLevantamento,
          dataFimLevantamento: shoppingProces.dataFimLevantamento,
          nome: element.nomeResponsavelComprador,
          empresaId: element.empresasId,
          CodigoAcesso: "",
          email: element.emailResponsavelComprador,
          emailAdministradorPC: shoppingProces.contactCompanyEmail,
        };

        items.push(data);
      }

      const body = {
        usuariosExternos: items,
      };

      const response = await LoginConsumer.createUserExternal(body);

      if (response.status !== 200) throw response;

      toast.success("Demandas solicitadas com sucesso!");
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "Erro ao solicitar as demandas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      loading,
      setLoading,
      page,
      setPage,
      handleChangePage,
      handleChangeRowsPerPage,
      demands,
      setDemands,
      getDemands,
      handleSelected,
      isDialogOpen,
      setIsDialogOpen,
      demand,
      setDemand,
      saveNewDemand,
      updateDemand,
      attributes,
      setAttributes,
      pageAttributes,
      setPageAttributes,
      orderBy,
      setOrderBy,
      orderByAttributes,
      setOrderByAttributes,
      descriptionAttributeSearch,
      setDescriptionAttributeSearch,
      getAttributes,
      handleChangePageAttrinutes,
      handleChangeRowsPerPageAttributes,
      attributesSelect,
      setAttributesSelect,
      loadingAttributes,
      setLoadingAttributes,
      handleDelete,
      requestDemands,
      gmvInitialTotal,
      setGmvInitialTotal,
      itemsToTrade,
      setItemsToTrade,
    };
  }, [
    page,
    loading,
    demands,
    isDialogOpen,
    demand,
    attributes,
    pageAttributes,
    orderBy,
    orderByAttributes,
    descriptionAttributeSearch,
    attributesSelect,
    loadingAttributes,
    gmvInitialTotal,
    itemsToTrade,
  ]);

  return (
    <ShoppingProccessDemandContext.Provider value={contextValue}>
      {children}
    </ShoppingProccessDemandContext.Provider>
  );
}

export default ShoppingProccessDemandContext;
