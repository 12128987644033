/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "src/components/Layout";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  Autocomplete,
  Card,
  Checkbox,
  Divider,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
} from "@mui/material";
import { palette } from "src/theme";
import { MaskStyle } from "src/styles/globalStyle";
import {
  ButtonDefault,
  ButtonSalveForm,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import TableComponent from "src/components/TableComponent";
import useInvoicing from "src/hooks/useInvoicing";
import CircularLoading from "src/components/Loading";
import { format } from "date-fns";
import Empty from "src/components/Empty";
import { formatCurrency } from "src/functions/text";
import DialogComponent from "src/components/Dialog";
import CreateOrUpdateInvoices from "src/pages/Requests/Invoices/CreateOrUpdateInvoices";
import useLayout from "src/hooks/useLayout";
import Invoices from "src/models/Invoices";
import useInvoices from "src/hooks/useInvoices";

const CreateOrUpdateInvoicing: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
      href: `/${lang}/invoicing`,
    },
    {
      name: translations[param]["register"],
    },
  ];

  const header = [
    {
      label: translations[param]["number"],
    },
    {
      label: translations[param]["issuance_date"],
    },
    {
      label: translations[param]["buyer"],
    },
    {
      label: translations[param]["supplier"],
    },
    {
      label: `${translations[param]["date"]} ${translations[param]["forecast"]} ${translations[param]["payment"]}`,
    },
    {
      label: `${translations[param]["value"]} NF`,
    },
    {
      label: "Código Item",
    },
    {
      label: "Descricão Item",
    },
    {
      label: "Quantidade",
    },
    {
      label: "Valor Item",
    },
    {
      label: translations[param]["rate"],
    },
    {
      label: "Valor a faturar",
    },
  ];

  const headerFlow = [
    {
      label: translations[param]["status"],
    },
    {
      label: translations[param]["date"],
    },
    {
      label: `${translations[param]["user"]} ${translations[param]["responsible"]}`,
    },
    {
      label: translations[param]["observation"],
    },
  ];

  const { openDialog, setOpenDialog } = useLayout();
  const {
    handleSelect: handleSelectInvoices,
    setEmissor,
    setTomador,
  } = useInvoices();

  const {
    page,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
    status,
    setStatus,
    supplyer,
    setSupplyer,
    invoicingDate,
    setInvoicingDate,
    invoicingValue,
    setInvoicingValue,
    paymentCondition,
    setPaymentCondition,
    paymentDate,
    setPaymentDate,
    paymentValue,
    setPaymentValue,
    observations,
    setObservations,
    disableSupplier,
    getSuppliersWithoutInvocies,
    getPaymentConditionsAdministrator,
    paymentConditions,
    loading,
    supplyers,
    invoicesSupplyer,
    setInvoicesSupplyer,
    invoicesSupplyerSelected,
    setInvoicesSupplyerSelected,
    statusInvoicing,
    getStatusInvoicing,
    handleNewSalve,
    invoicingSelect,
    getStatusAndamento,
    adamentoStatus,
    cleanFilters,
    handleUpdate,
    dataInicialPrevPgto,
    setDataInicialPrevPgto,
    dataFinalPrevPgto,
    setDataFinalPrevPgto,
    generateCSV,
    disablePaymentCondition,
    setDisablePaymentCondition,
  } = useInvoicing();

  useEffect(() => {
    getPaymentConditionsAdministrator();
    getStatusInvoicing();

    if (invoicingSelect?.id) getStatusAndamento(`${invoicingSelect.id}`);
  }, []);

  useEffect(() => {
    if (!invoicingSelect) {
      const initialState = statusInvoicing.find(
        (e) => e.descricao === "A Faturar"
      );
      if (initialState && initialState?.id) {
        setStatus(initialState.id);
      }
    }
  }, [statusInvoicing]);

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() => {
        cleanFilters();
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoicing"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid container display="flex" alignItems="center" spacing={1}>
          <Grid xs={12} md={4}>
            <FormLabel>Data Início Previsão Pagamento NF</FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                disabled={!!invoicingSelect?.id}
                value={dataInicialPrevPgto}
                onChange={(e) => setDataInicialPrevPgto(e.target.value)}
                style={{ width: "98%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>Data Fim Previsão Pagamento NF</FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                disabled={!!invoicingSelect?.id}
                min="1970-01-01"
                value={dataFinalPrevPgto}
                onChange={(e) => setDataFinalPrevPgto(e.target.value)}
                style={{ width: "98%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={4} display="flex" justifyContent="flex-end">
            <ButtonSearch
              label={translations[param]["search"]}
              size="medium"
              onClick={getSuppliersWithoutInvocies}
              disabled={
                !!invoicingSelect?.id ||
                loading ||
                dataInicialPrevPgto === "" ||
                setDataFinalPrevPgto === ""
              }
            />
          </Grid>
          <Grid xs={12}>
            <FormLabel>{translations[param]["supplier"]}</FormLabel>
            <Autocomplete
              disablePortal
              fullWidth
              id="show_select_administrator_responsible"
              options={supplyers}
              value={supplyer}
              disabled={!!invoicingSelect?.id || disableSupplier}
              getOptionLabel={(option: any) =>
                option?.nomeFantasiaEmissor ?? ""
              }
              onChange={(_: any, value: any) => {
                if (value?.idEmissor) {
                  setSupplyer(value);
                  setInvoicesSupplyer(value.notaFiscal);

                  if (value?.condicaoPagamentoS4M) {
                    setPaymentCondition(value.condicaoPagamentoS4M);
                    setDisablePaymentCondition(true);
                  } else {
                    setPaymentCondition("select");
                    setDisablePaymentCondition(false);
                  }
                } else {
                  setSupplyer(null);
                  setInvoicesSupplyer([]);
                  setPaymentCondition("select");
                  setDisablePaymentCondition(false);
                }
              }}
              renderInput={(params) =>
                loading ? (
                  <CircularLoading />
                ) : (
                  <TextField
                    {...params}
                    label="Selecione:"
                    size="small"
                    fullWidth
                  />
                )
              }
            />
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>
              Data Faturamento Supply4Med<span style={{ color: "red" }}>*</span>
            </FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                value={invoicingDate}
                required
                onChange={(e) => setInvoicingDate(e.target.value)}
                style={{ width: "95%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>
              {translations[param]["value"]} {translations[param]["invoicing"]}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              size="small"
              id="value"
              name="value"
              label="R$ ##.###,00"
              variant="outlined"
              disabled
              value={invoicingValue.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
              fullWidth
              style={{ width: "98%" }}
            />
          </Grid>
          <Grid xs={4} md={4}>
            <FormLabel>
              {translations[param]["payment_condition"]}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Select
              labelId="show_select_type"
              id="show_select_status"
              value={paymentCondition}
              size="small"
              disabled={disablePaymentCondition}
              required
              onChange={(event: SelectChangeEvent) => {
                setPaymentCondition(event.target.value);
              }}
              fullWidth
            >
              <MenuItem value="select" disabled>
                {translations[param]["select"]}
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              {paymentConditions?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>
              {translations[param]["date"]} {translations[param]["payment"]}
            </FormLabel>
            <MaskStyle>
              <input
                type="date"
                name="begin_date"
                placeholder="dd/mm/yyyy"
                min="1970-01-01"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                style={{ width: "98%" }}
              />
            </MaskStyle>
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>
              {translations[param]["value"]} {translations[param]["payment"]}
            </FormLabel>
            <TextField
              size="small"
              id="value"
              name="value"
              label="R$ ##.###,00"
              variant="outlined"
              value={paymentValue}
              onChange={(e) => {
                e = formatCurrency(e);
                setPaymentValue(e.target.value);
              }}
              fullWidth
              style={{ width: "98%" }}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <FormLabel>{translations[param]["status"]}</FormLabel>
            <Select
              labelId="show_select_type"
              id="show_select_status"
              value={status}
              size="small"
              disabled={!invoicingSelect ? true : false}
              onChange={(event: SelectChangeEvent) =>
                setStatus(event.target.value as string)
              }
              fullWidth
            >
              <MenuItem value="select" disabled>
                Selecione:
              </MenuItem>
              <MenuItem value="">...</MenuItem>
              {statusInvoicing?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.descricao}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {invoicingSelect?.status && invoicingSelect?.statusId !== status && (
            <Grid
              display="flex"
              justifyContent="end"
              sx={{ minWidth: "100%", marginTop: 2 }}
            >
              <Grid sx={{ minWidth: "53%" }}>
                <TextField
                  id="observations"
                  label={translations[param]["note_about_changing_status"]}
                  multiline
                  maxRows={4}
                  value={observations}
                  onChange={(e) => setObservations(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["invoices"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {invoicesSupplyer?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {invoicesSupplyer?.map((item: any) =>
                  item?.notaFiscalItems?.map((value: any) => (
                    <TableRow hover key={item.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let invoce: Invoices = new Invoices(
                            item.id,
                            item.numero,
                            item.dataEmissao,
                            item.dataPrevisaoPagamento,
                            `${item.valor
                              .toString()
                              .replace("R$", "")
                              .replaceAll(".", "")
                              .replace(",", ".")
                              .trim()}`,
                            `${item.valorLiquido
                              .toString()
                              .replace("R$", "")
                              .replaceAll(".", "")
                              .replace(",", ".")
                              .trim()}`,
                            item.emissor,
                            item.tomador,
                            item.nomeFantasiaEmissor,
                            item.nomeFantasiaTomador,
                            "",
                            item.notaFiscalItens,
                            []
                          );

                          setEmissor({
                            id: item.emissor,
                            label: item.nomeFantasiaEmissor,
                            cnpj: item?.cnpjEmissor,
                            nome: item.nomeFantasiaEmissor,
                            razaoSocial: item.nomeFantasiaEmissor,
                          });
                          setTomador(item.tomador);

                          handleSelectInvoices(invoce);
                          setOpenDialog(true);
                        }}
                      >
                        {item?.numero}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item?.dataEmissao
                          ? format(new Date(item.dataEmissao), "dd/MM/yyyy")
                          : null}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item?.razaoSocialTomador}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item?.razaoSocialEmissor}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item.dataPrevisaoPagamento
                          ? format(
                              new Date(item.dataPrevisaoPagamento),
                              "dd/MM/yyyy"
                            )
                          : null}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item?.valor?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "0,00"}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {value?.codigoItem ?? ""}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {value?.descricaoItem ?? ""}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {value?.quantidade ?? ""}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {value?.valor?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "0,00"}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {item?.taxa}%
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => {}}>
                        {value?.valorFaturamentoS4M?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        }) ?? "0,00"}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={invoicesSupplyerSelected.some((supplyer) =>
                            supplyer.notaFiscalItems.includes(value)
                          )}
                          size="small"
                          onClick={() => {
                            const supplyerIndex =
                              invoicesSupplyerSelected.findIndex(
                                (supplyer) => supplyer.id === item.id
                              );

                            if (supplyerIndex === -1) {
                              // Adiciona o item com o value selecionado
                              const sum =
                                invoicingValue + item?.valorFaturamentoS4M;
                              setInvoicingValue(sum);

                              const newData = [
                                ...invoicesSupplyerSelected,
                                {
                                  ...item,
                                  notaFiscalItems: [value],
                                },
                              ];
                              setInvoicesSupplyerSelected(newData);
                            } else {
                              const supplyer =
                                invoicesSupplyerSelected[supplyerIndex];
                              const notaFiscalItems =
                                supplyer.notaFiscalItems.includes(value)
                                  ? supplyer.notaFiscalItems.filter(
                                      (nfi: any) => nfi !== value
                                    )
                                  : [...supplyer.notaFiscalItems, value];

                              const sum = supplyer.notaFiscalItems.includes(
                                value
                              )
                                ? invoicingValue - item?.valorFaturamentoS4M
                                : invoicingValue + item?.valorFaturamentoS4M;
                              setInvoicingValue(sum);

                              const newData = invoicesSupplyerSelected
                                .map((supplyer, index) =>
                                  index === supplyerIndex
                                    ? { ...supplyer, notaFiscalItems }
                                    : supplyer
                                )
                                .filter(
                                  (supplyer) =>
                                    supplyer.notaFiscalItems.length > 0
                                );

                              setInvoicesSupplyerSelected(newData);
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableComponent>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
          justifyContent="end"
        >
          <ButtonDefault
            label="Fechar"
            disabled={loading}
            onClick={() => {
              router(`/${lang}/invoicing`);
            }}
          />
          <ButtonDefault
            label="Exportar Planilha"
            onClick={() => {
              if (invoicingSelect?.id) {
                generateCSV(invoicesSupplyerSelected);
              } else {
                generateCSV(invoicesSupplyerSelected);
              }
            }}
          />
          <ButtonDefault
            label="Enviar email"
            onClick={() => {}}
            disabled={true}
          />
          {invoicingSelect?.id ? (
            <ButtonSalveForm
              label="Salvar"
              disabled={loading}
              onClick={() => {
                const cancelStatus = statusInvoicing.find(
                  (e) => e.descricao === "Cancelado"
                );

                handleUpdate(cancelStatus?.id);
              }}
            />
          ) : (
            <ButtonSalveForm
              label="Incluir"
              disabled={loading}
              onClick={handleNewSalve}
            />
          )}
        </Grid>
      </Card>

      <Card sx={{ marginTop: "2%", padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>{translations[param]["billing_status_flow"]}</Grid>
        </Grid>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 20,
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12}>
            {adamentoStatus?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={headerFlow}
                page={{
                  page: 0,
                  rowsPerPage: 10,
                  total: 10,
                }}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              >
                {adamentoStatus.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item?.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.data
                        ? format(new Date(item.data), "dd/MM/yyyy")
                        : null}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.usuarioSistemaResponsavel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.observacao}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <CreateOrUpdateInvoices showButtonClose callFromScreenInvoice={true} />
      </DialogComponent>
    </Layout>
  );
};

export default CreateOrUpdateInvoicing;
