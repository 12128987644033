/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  TableRow,
  TableCell,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import TableComponent from "src/components/TableComponent";
import SearchIcon from "@mui/icons-material/Search";
import { palette } from "src/theme";
import CircularLoading from "src/components/Loading";
import { toast } from "react-toastify";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import invoicesConsumer from "src/services/invoices";
import useInvoices from "src/hooks/useInvoices";
import { format } from "date-fns";

const InvoicingShoppingProccess = () => {
  const [loading, setLoading] = useState(false);
  const [invoces, setInvoces] = useState([]);

  const { shoppingProcesSelect } = useShoppingProcess();

  const [header] = useState([
    { label: "Número NF", orderBackend: "number" },
    { label: "Emissor", orderBackend: "emissor" },
    { label: "Tomador", orderBackend: "tomador" },
    { label: "Data Emissão", orderBackend: "date" },
    { label: "Valor Total", orderBackend: "totalValue" },
    { label: "Valor Líquido", orderBackend: "netValue" },
  ]);

  const { page, handleChangePage, handleChangeRowsPerPage, setPage } =
    useInvoices();

  const getInvoices = async () => {
    setLoading(true);

    try {
      const response = await invoicesConsumer.get(
        page,
        "",
        "",
        "",
        "",
        "",
        "",
        `${shoppingProcesSelect?.id}`
      );

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setInvoces(response.data.items);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, [page]);

  if (loading) return <CircularLoading />;

  return (
    <Box sx={{ padding: 3 }}>
      <Card sx={{ width: "100%", padding: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={12}>
            <TextField
              label="Número da Nota Fiscal"
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <TextField
              label="Valor NF"
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <Box sx={{ mr: 1 }}>R$</Box>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              startIcon={<SearchIcon />}
            >
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ width: "100%", padding: 2, marginTop: 3 }}>
        <Typography gutterBottom variant="h6" component="div">
          Notas Fiscais
        </Typography>
        <Divider
          style={{
            backgroundColor: palette.primary.main,
            marginBottom: 15,
          }}
        />

        <TableComponent
          header={header}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {invoces.map((row: any) => (
            <TableRow key={row.id} hover>
              <TableCell>{row.numero}</TableCell>
              <TableCell>{row.nomeFantasiaEmissor}</TableCell>
              <TableCell>{row.nomeFantasiaTomador}</TableCell>
              <TableCell>
                {row.dataEmissao
                  ? format(new Date(row.dataEmissao), "dd/MM/yyyy")
                  : "Não informado"}
              </TableCell>
              <TableCell>
                {row.valor?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </TableCell>
              <TableCell>
                {row.valorLiquido?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableComponent>
      </Card>
    </Box>
  );
};

export default InvoicingShoppingProccess;
