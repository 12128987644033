import { iUser } from "src/interfaces/user";
import httpClientLogin from "src/http/login";
import api_version from "src/constants/api_version.json";
import User from "src/models/User";

class userConsumer {
  static get(idCompany: string) {
    return httpClientLogin.get(
      `/api/v${api_version.version}/users/get?empresaId=${idCompany}`
    );
  }

  static getById(id: number) {
    return httpClientLogin.get(
      `/api/v${api_version.version}/users/getbyid/${id}`
    );
  }

  static create(user: iUser) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/users/createuser/create`,
      User.adapterToBodyCreate(user)
    );
  }

  static update(user: iUser) {
    return httpClientLogin.put(
      `/api/v${api_version.version}/users/updateuser/${user.id}/usuario`,
      User.adapterToBodyUpdate(user)
    );
  }

  static delete(id: string) {
    return httpClientLogin.delete(
      `/api/v${api_version.version}/users/deletebyid/${id}`
    );
  }

  static getRoles() {
    return httpClientLogin.get(`/api/v${api_version.version}/roles/get`);
  }
}

export default userConsumer;
