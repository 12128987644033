/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TableCell,
  TableRow,
  TextField,
  Grid,
} from "@mui/material";
import CircularLoading from "src/components/Loading";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import {
  ButtonClosedForm,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import useCompany from "src/hooks/useCompany";
import TableComponent from "src/components/TableComponent";
import { iPaymentCondition } from "src/interfaces/paymentCondition";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Empty from "src/components/Empty";

import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";

const PaymentCondition: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { companySelected } = useCompany();

  const {
    loading,
    handleNewSalve,
    handleUpdate,
    paymentConditionSelect,
    code,
    setCode,
    description,
    setDescription,
    daysNumber,
    setDaysNumber,
    installments,
    setInstallments,
    active,
    setActive,
    getPaymentConditions,
    paymentConditions,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    handleDelete,
  } = usePaymentCondition();

  useEffect(() => {
    getPaymentConditions(companySelected);
  }, []);

  if (loading) return <CircularLoading />;

  const header = [
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["number_in_days"],
    },
    {
      label: translations[param]["installment_quantity"],
    },
    {
      label: translations[param]["active"],
    },
  ];

  return (
    <>
      <Card sx={{ padding: 5, width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!paymentConditionSelect?.id) {
              handleNewSalve();
            } else {
              handleUpdate();
            }
          }}
        >
          <Grid container spacing={1}>
            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["code"]}: </FormLabel>
              <TextField
                fullWidth
                id="code"
                name="code"
                label={translations[param]["code"]}
                required
                variant="outlined"
                size="small"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>

            <Grid xs={12} md={12} lg={8} item>
              <FormLabel>{translations[param]["description"]}: </FormLabel>
              <TextField
                fullWidth
                id="description"
                name="description"
                label={translations[param]["description"]}
                required
                variant="outlined"
                size="small"
                sx={{ marginRight: "4%" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>

            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>{translations[param]["number_in_days"]}: </FormLabel>
              <TextField
                fullWidth
                id="daysNumber"
                name="daysNumber"
                label={translations[param]["number_in_days"]}
                required
                variant="outlined"
                size="small"
                value={daysNumber}
                type="number"
                onChange={(e) => setDaysNumber(parseInt(e.target.value))}
              />
            </Grid>
            <Grid xs={12} md={12} lg={4} item>
              <FormLabel>
                {translations[param]["installment_quantity"]}:{" "}
              </FormLabel>
              <TextField
                fullWidth
                id="installments"
                name="installments"
                label={translations[param]["installment_quantity"]}
                required
                variant="outlined"
                size="small"
                value={installments}
                type="number"
                onChange={(e) => setInstallments(parseInt(e.target.value))}
              />
            </Grid>

            <Grid
              md={12}
              lg={4}
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="active"
                      name="active"
                      size="small"
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                    />
                  }
                  label={translations[param]["active"]}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={() => handleSelected(null)}
            />
            <FormControl>
              {paymentConditionSelect?.id ? (
                <ButtonSalveForm
                  label={translations[param]["update"]}
                  type="submit"
                />
              ) : (
                <ButtonInclude
                  label={translations[param]["include"]}
                  type="submit"
                />
              )}
            </FormControl>
          </Grid>
        </form>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {paymentConditions.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {paymentConditions?.map((data: iPaymentCondition) => (
                <TableRow
                  hover
                  key={data.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelected(data)}
                  >
                    {data.code}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.description}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.daysNumber}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.installments}
                  </TableCell>
                  <TableCell onClick={() => handleSelected(data)}>
                    {data.active ? (
                      <CheckCircleIcon color="success" fontSize="small" />
                    ) : (
                      <DoNotDisturbAltIcon color="error" fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: translations[param][
                            "delete_payment_condition_confirmation"
                          ],
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) handleDelete(data);
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default PaymentCondition;
