/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Grid,
} from "@mui/material";
import { MaskStyle } from "src/styles/globalStyle";
import ReactInputMask from "react-input-mask";
import useCompany from "src/hooks/useCompany";
import { ButtonSalveForm } from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import useNetwork from "src/hooks/useNetwork";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import { useParams } from "react-router-dom";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import useCompanyContact from "src/hooks/useCompanyContact";
import AdditionalInformations from "./AdditionalInformations";
import SocialMedia from "./SocialMedia";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";

const GeneralDates: React.FC = () => {
  const {
    cnpj,
    setCnpj,
    active,
    setActive,
    socialName,
    setSocialName,
    name,
    setName,
    buyer,
    setBuyer,
    supplier,
    setSupplier,
    handleSalveOrUpdatedUser,
    loading,
    network,
    setNetwork,
    administrator,
    setAdministrator,
    handleVerifyCnpj,
    codeOutdoor,
    setCodeOutdoor,
    foreignCompany,
    setForeignCompany,
    contactCompanyFromApiSerpros,
    numeroLeitos,
    setNumeroLeitos,
    site,
    setSite,
    getEmpresaClassificacaoTipo,
    getRedesSociais,
  } = useCompany();

  const { getNetworks, networks } = useNetwork();
  const { getAreas, areas, getTypes, types } = useCompanyContact();

  const { lang } = useParams();
  const param = lang as keyof tLanguage;

  useEffect(() => {
    getNetworks();
    getAreas();
    getTypes();
    getEmpresaClassificacaoTipo();
    getRedesSociais();
  }, []);

  useEffect(() => {
    if (contactCompanyFromApiSerpros.length > 0) {
      Swal.fire({
        title: translations[param]["attention"],
        text: translations[param]["search_performed_save_changes"],
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: palette.primary.main,
        cancelButtonColor: palette.error.main,
        confirmButtonText: translations[param]["yes"],
        cancelButtonText: translations[param]["no"],
      }).then((result) => {
        if (result.isConfirmed) {
          handleSalveOrUpdatedUser(undefined, areas);
        }
      });
    }
  }, [contactCompanyFromApiSerpros]);

  if (loading) return <CircularLoading />;

  return (
    <>
      <form onSubmit={handleSalveOrUpdatedUser}>
        <Card sx={{ padding: 5, width: "100%" }}>
          <Grid container>
            {/* CNPJ Section */}
            <Grid container xs={12} alignItems="center">
              <Grid item xs={12} md={12} lg={4} display="flex" alignItems="end">
                <Grid sx={{ width: "100%" }}>
                  <FormLabel>{translations[param]["cnpj"]}: </FormLabel>
                  <FormControl fullWidth>
                    <MaskStyle>
                      <ReactInputMask
                        mask="99.999.999/9999-99"
                        name="cnpj"
                        placeholder={translations[param]["cnpj"]}
                        value={cnpj}
                        onChange={(e) => setCnpj(e.target.value)}
                        disabled={foreignCompany}
                      />
                    </MaskStyle>
                  </FormControl>
                </Grid>
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() =>
                    Swal.fire({
                      title: translations[param]["attention"],
                      text: translations[param]["action_update_company_data"],
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: palette.primary.main,
                      cancelButtonColor: palette.error.main,
                      confirmButtonText: translations[param]["yes"],
                      cancelButtonText: translations[param]["no"],
                    }).then((result) => {
                      if (result.isConfirmed) handleVerifyCnpj(types);
                    })
                  }
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <FormLabel>{translations[param]["foreign_code"]}: </FormLabel>
                <TextField
                  id="code_outdoor"
                  fullWidth
                  name="code_outdoor"
                  label={translations[param]["foreign_code"]}
                  variant="outlined"
                  size="small"
                  value={codeOutdoor}
                  disabled={!foreignCompany}
                  onChange={(e) => setCodeOutdoor(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                display="flex"
                justifyContent="end"
                sx={{ mt: 2 }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="foreignCompany"
                        name="foreignCompany"
                        size="small"
                        checked={foreignCompany}
                        onChange={(e) => {
                          if (!e.target.checked) setCodeOutdoor("");
                          setForeignCompany(e.target.checked);
                        }}
                      />
                    }
                    label={translations[param]["foreign_company"]}
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    }
                    label={translations[param]["active"]}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Social Reason Section */}
            <Grid item xs={12}>
              <FormLabel>{translations[param]["social_reason"]}: </FormLabel>
              <TextField
                id="razaoSocial"
                fullWidth
                name="razaoSocial"
                label={translations[param]["social_reason"]}
                required
                variant="outlined"
                size="small"
                value={socialName}
                onChange={(e) => setSocialName(e.target.value)}
              />
            </Grid>

            {/* Fantasy Name Section */}
            <Grid item xs={12}>
              <FormLabel>{translations[param]["fantasy_name"]}: </FormLabel>
              <TextField
                id="name"
                fullWidth
                name="name"
                label={translations[param]["fantasy_name"]}
                required
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {/* Network Section */}
            <Grid item xs={12} md={12} lg={4}>
              <FormLabel>{translations[param]["network"]}: </FormLabel>
              <Select
                labelId="show_select_suppliers"
                id="show_select_suppliers"
                value={network}
                size="small"
                fullWidth
                onChange={(event: SelectChangeEvent) => {
                  setNetwork(event.target.value as string);
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {networks.map((network) => (
                  <MenuItem key={network.id} value={network.id}>
                    {network.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Leitos and Site Section */}
            <Grid item xs={12} md={12} lg={4}>
              <FormLabel>Número Leitos</FormLabel>
              <TextField
                id="numeroLeitos"
                name="numeroLeitos"
                label="Número Leitos"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                disabled={!buyer}
                value={numeroLeitos}
                onChange={(e) => setNumeroLeitos(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FormLabel>Site</FormLabel>
              <TextField
                id="site"
                name="site"
                label="Site"
                variant="outlined"
                size="small"
                fullWidth
                value={site}
                onChange={(e) => setSite(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="open site in new tab"
                        onClick={() => {
                          if (site) {
                            let redirect = site;

                            if (!redirect.includes("http")) {
                              redirect = `https://${redirect}`;
                            }

                            window.open(redirect, "_blank");
                          }
                        }}
                        edge="end"
                      >
                        <ScreenShareOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Roles Section */}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="administrator"
                          name="administrator"
                          size="small"
                          checked={administrator}
                          onChange={(e) => setAdministrator(e.target.checked)}
                        />
                      }
                      label={translations[param]["manager"]}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="buyer"
                          name="buyer"
                          size="small"
                          checked={buyer}
                          onChange={(e) => setBuyer(e.target.checked)}
                          sx={!buyer && !supplier ? { color: "#d32f2f" } : {}}
                        />
                      }
                      label={translations[param]["buyer"]}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="supplier"
                          name="supplier"
                          size="small"
                          checked={supplier}
                          onChange={(e) => setSupplier(e.target.checked)}
                          sx={!buyer && !supplier ? { color: "#d32f2f" } : {}}
                        />
                      }
                      label={translations[param]["supplier"]}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 5, width: "100%", mt: 5 }}>
          <AdditionalInformations />
        </Card>

        <Card sx={{ padding: 5, width: "100%", mt: 5 }}>
          <SocialMedia />
        </Card>

        <Grid xs={12} sx={{ marginTop: 5 }}>
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            <ButtonSalveForm
              label={translations[param]["save"]}
              type="submit"
              disabled={
                !foreignCompany &&
                ((!buyer && !supplier) ||
                  cnpj?.replace(/[^a-zA-Z0-9]/g, "").length !== 14)
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default GeneralDates;
