/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "src/components/Layout";
import MenuTabs from "src/components/MenuTabs";
import { iContentTabs, iTabs } from "src/interfaces/iTabs";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { ButtonClosedForm } from "src/components/ButtonsCommons";
import { useNavigate, useParams } from "react-router-dom";
import useRequest from "src/hooks/useRequest";
import CreateOrUpdate from "../CreateOrUpdate";
import Invoices from "../Invoices";
import useCompany from "src/hooks/useCompany";
import { useEffect } from "react";
import ExportToExcel from "src/components/ExportToExcel";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import translations from "src/_i18n/translations.json";
import { tLanguage } from "src/types/language";
import { palette } from "src/theme";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const tabs: Array<iTabs> = [
  {
    label: "Dados Gerais",
  },
  {
    label: "Notas Fiscais",
  },
];

const RegisterSteps: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    activeTabsMenu,
    requestSelect,
    handleSelected,
    page,
    setPage,
    CSV,
    CSVSupply,
    cleanFilters,
    importInvoice,
  } = useRequest();

  const { setValue } = useCompany();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: "Pedidos",
      href: `/${lang}/requests`,
    },
    {
      name: `Cadastro - ${requestSelect?.number ?? "Incluir"}`,
    },
  ];

  const contentTabs: Array<iContentTabs> = [
    {
      value: <CreateOrUpdate />,
    },
    {
      value: <Invoices />,
    },
  ];

  useEffect(() => {
    return () => {
      setValue(0);
    };
  }, []);

  const buttons = [
    requestSelect?.id && (
      <Button
        component="label"
        onChange={(event) => {
          Swal.fire({
            title: translations[param]["attention"],
            text: `A ação irá iniciar a importação das notas. deseja continuar ? `,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: palette.primary.main,
            cancelButtonColor: palette.error.main,
            confirmButtonText: translations[param]["yes"],
            cancelButtonText: translations[param]["no"],
          }).then((result) => {
            if (result.isConfirmed) importInvoice(event, requestSelect.id);
          });
        }}
        variant="contained"
        size="small"
        color="secondary"
      >
        Importar Nota Fiscal
        <VisuallyHiddenInput type="file" />
      </Button>
    ),
    requestSelect?.id && (
      <ExportToExcel
        data={CSV}
        fileName="Planilha_Importacao_Carrinho_Catalogo"
        buttonName="Exportar - Paradigma"
      />
    ),
    requestSelect?.id && (
      <ExportToExcel
        data={CSVSupply}
        fileName="Planilha_Importacao_Carrinho_Catalogo"
        buttonName="Exportar Paradigma - Fornecedor"
      />
    ),
    <ButtonClosedForm
      label="Fechar"
      onClick={() => {
        handleSelected(null);
        router(-1);
      }}
    />,
  ];

  return (
    <Layout
      breadcrumbs={breadcrumb}
      buttons={buttons}
      callMenu={() => {
        cleanFilters();
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <MenuTabs
        tabs={tabs}
        contentTabs={contentTabs}
        isDisabled={!requestSelect?.id ? activeTabsMenu : false}
      />
    </Layout>
  );
};

export default RegisterSteps;
