import httpClientInvoicing from "src/http/invoicing";
import { iInvoicing } from "src/interfaces/invoicing";
import { iPage } from "src/interfaces/layout";
import Invoicing from "src/models/Invoicing";

class invoicingConsumer {
  static get(
    page: iPage,
    cleint?: string,
    nf?: string,
    from?: string,
    to?: string,
    status?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();

    if (cleint) {
      search.append("CNPJ", `${cleint.replace(/\D/g, "")}`);
    }

    if (nf) {
      search.append("NumeroNF", `${nf}`);
    }

    if (status && status !== "select") {
      search.append("FaturamentoStatusId", `${status}`);
    }

    if (from && to) {
      search.append("DataInicio", `${from}`);
      search.append("DataFim", `${to}`);
    }

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientInvoicing.get(`/Faturamento?${search.toString()}`);
  }

  static getItems(
    page: iPage,
    cleint?: string,
    nf?: string,
    from?: string,
    to?: string,
    status?: string,
    orderBy?: any
  ) {
    const search = new URLSearchParams();

    if (cleint) {
      search.append("CNPJ", `${cleint.replace(/\D/g, "")}`);
    }

    if (nf) {
      search.append("NumeroNF", `${nf}`);
    }

    if (status && status !== "select") {
      search.append("FaturamentoStatusId", `${status}`);
    }

    if (from && to) {
      search.append("DataInicio", `${from}`);
      search.append("DataFim", `${to}`);
    }

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientInvoicing.get(`/FaturamentoItens?${search.toString()}`);
  }

  static getStatus(page: iPage) {
    const search = new URLSearchParams();

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientInvoicing.get(`/FaturamentoStatus?${search.toString()}`);
  }

  static created(invoicing: iInvoicing) {
    return httpClientInvoicing.post(
      `/Faturamento`,
      Invoicing.adapterToBody(invoicing)
    );
  }

  static updated(invoicing: iInvoicing) {
    return httpClientInvoicing.put(
      `/Faturamento`,
      Invoicing.adapterToBody(invoicing)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientInvoicing.delete(`/Faturamento?id=${id}`);
  }

  static createdVinculateWithInvoicingAndNote(body: any) {
    return httpClientInvoicing.post(`/FaturamentoNotaFiscal`, body);
  }

  static deletedInvoicingAndNote(id: string | null | undefined) {
    return httpClientInvoicing.delete(`/FaturamentoNotaFiscal?id=${id}`);
  }

  static getVinculateWithStatus(page: any, idInvoicing: string) {
    const search = new URLSearchParams();

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());
    search.append("FaturamentoId", idInvoicing);

    return httpClientInvoicing.get(
      `/AndamentoStatusFaturamento?${search.toString()}`
    );
  }

  static createdVinculateWithStatus(body: any) {
    return httpClientInvoicing.post(`/AndamentoStatusFaturamento`, body);
  }

  static createdBatchBilling(invoicing: any) {
    return httpClientInvoicing.post(
      `/Faturamento/GerarFaturamentoLote`,
      invoicing
    );
  }
}

export default invoicingConsumer;
