/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { theme } from "src/theme";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useCompanyContact from "src/hooks/useCompanyContact";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";

const SocialMediaContact: React.FC = () => {
  const {
    redesSociais,
    redesSociaisToSearch,
    handleInputChange,
    handleAddSection,
    handleRemoveSection,
  } = useCompanyContact();

  const add = (index: number, value: any) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            redesSociaisId: value?.id ?? "",
            descricao: value?.descricao ?? "",
          },
        },
      },
      index,
      "redesSociais"
    );
  };

  const remove = (index: number) => {
    handleInputChange(
      {
        target: {
          name: "multiChange",
          value: {
            redesSociaisId: "",
            descricao: "",
          },
        },
      },
      index,
      "redesSociais"
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Redes Sociais</Typography>
        <IconButton
          color="primary"
          onClick={() => handleAddSection("redesSociais")}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <br />
      <Grid container spacing={2}>
        {redesSociais?.map((section: any, index: any) => (
          <Box
            key={index}
            marginBottom={theme.spacing(2)}
            display="flex"
            sx={{ width: "100%" }}
          >
            <Grid container spacing={2} wrap="nowrap" sx={{ width: "100%" }}>
              {[
                { label: "Rede Social", name: "rede" },
                { label: "Link", name: "valor" },
              ]?.map((field, idx) => {
                return (
                  <Grid xs={12} key={idx} sx={{ width: "100%" }}>
                    {field.name === "rede" && (
                      <Autocomplete
                        disablePortal
                        options={redesSociaisToSearch}
                        freeSolo
                        fullWidth
                        id="show_select_administrator_code"
                        getOptionLabel={(option: any) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option?.descricao || "";
                        }}
                        value={
                          redesSociais[index]?.id
                            ? redesSociaisToSearch.find(
                                (item: any) =>
                                  item.id === redesSociais[index]?.id
                              ) || redesSociais[index]?.descricao.toString()
                            : null
                        }
                        size="small"
                        onChange={(_, value) => {
                          if (value) {
                            add(index, value);
                          } else {
                            remove(index);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Tipo" size="small" />
                        )}
                      />
                    )}
                    {field.name === "valor" && (
                      <TextField
                        placeholder={field.label}
                        fullWidth
                        size="small"
                        required
                        name={field.name}
                        label={field.label}
                        value={section[field.name]}
                        onChange={(e) =>
                          handleInputChange(e, index, "redesSociais")
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="open site in new tab"
                                onClick={() => {
                                  if (section[field.name]) {
                                    let redirect = section[field.name];

                                    if (!redirect.includes("http")) {
                                      redirect = `https://${redirect}`;
                                    }

                                    window.open(redirect, "_blank");
                                  }
                                }}
                                edge="end"
                              >
                                <ScreenShareOutlinedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                );
              })}
              <IconButton
                onClick={() =>
                  handleRemoveSection(index, "redesSociais", section)
                }
                style={{ marginTop: theme.spacing(1) }}
              >
                <RemoveCircleOutlineIcon color="error" />
              </IconButton>
            </Grid>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default SocialMediaContact;
