/* eslint-disable react-hooks/exhaustive-deps */
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustomStart } from "src/components/ItemCustom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconExcel from "src/assets/images/IconExcel.svg";
import IconRed from "src/assets/images/Icon-red.svg";
import {
  Button,
  FormLabel,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { styled } from "@mui/material/styles";
import useImport from "src/hooks/useImport";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const InputImport = ({
  description,
  file,
  name,
  type,
  isLog = true,
}: {
  description: string;
  file: any;
  name: string;
  type: string;
  isLog?: boolean;
}) => {
  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const {
    setOpenDialog,
    handleFileChange,
    fileName,
    setFileName,
    fileType,
    setFileType,
    setSelectedFile,
    getAudits,
  } = useImport();

  return (
    <Grid xs={12} sm={12} md={12}>
      <ItemCustomStart>
        <FormLabel sx={{ minWidth: "21%" }}>{description}: </FormLabel>

        <Button
          component="label"
          size="medium"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onChange={(event) => handleFileChange(event, type)}
          sx={
            !isScreenSmall
              ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
              : {}
          }
          disabled={
            type === "" ? true : fileType === "" ? false : type !== fileType
          }
        >
          Escolher aquivos
          <VisuallyHiddenInput type="file" />
        </Button>
        {!isScreenSmall && (
          <TextField
            size="small"
            id="company"
            value={fileType === type ? fileName : "Nenhum arquivo..."}
            sx={{ minWidth: "50%" }}
            InputProps={{
              sx: {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
              style: {
                height: 37,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setFileType("");
                    setFileName("");
                    setSelectedFile(null);
                  }}
                >
                  <CloseOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        <a
          style={{ marginLeft: "1%" }}
          href={file}
          target="_blank"
          rel="noopener noreferrer"
          download={name}
          type="text/csv"
        >
          <img src={IconExcel} alt="icon_excel" />
        </a>
        {isLog && (
          <img
            src={IconRed}
            alt="icon_excel"
            style={{ marginLeft: "1%", cursor: "pointer" }}
            onClick={async () => {
              await getAudits(type);
              setOpenDialog(true);
              setFileType(type);
            }}
          />
        )}
      </ItemCustomStart>
    </Grid>
  );
};

export default InputImport;
