import httpClientRequest from "src/http/request";
import httpClientImportInvoice from "src/http/importInvoice";
import { iInvoices } from "src/interfaces/invoices";
import { iPage } from "src/interfaces/layout";
import Ivoices from "src/models/Invoices";

class invoicesConsumer {
  static get(
    page: iPage,
    requestId?: string,
    number?: string,
    value?: string,
    from?: string,
    to?: string,
    orderBy?: any,
    shoppingProccessId?: any
  ) {
    const search = new URLSearchParams();

    if (number) {
      search.append("Numero", `${number}`);
    }

    if (value) {
      search.append("Valor", `${value}`);
    }

    if (shoppingProccessId) {
      search.append("ProcessoCompraId", `${shoppingProccessId}`);
    }

    if (from && to) {
      search.append("DataInicial", `${from}`);
      search.append("DataFinal", `${to}`);
    }

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    search.append("PedidoId", `${requestId}`);
    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientRequest.get(`/NotaFiscal?${search.toString()}`);
  }

  static getInvoiceWithSupplier(
    page: iPage,
    startDate?: string,
    endDate?: string,
    prevPaymentStartDate?: string,
    prevPaymentEndDate?: string,
    emissorId?: string,
    processoCompra?: boolean,
    faturado?: string
  ) {
    const search = new URLSearchParams();

    if (startDate && endDate) {
      search.append("DataInicialEmissao", `${startDate}`);
      search.append("DataFinalEmissao", `${endDate}`);
    }

    if (emissorId) {
      search.append("EmissorId", `${emissorId}`);
    }

    if (processoCompra) {
      search.append("ProcessoCompra", `${processoCompra}`);
    }

    if (prevPaymentStartDate && prevPaymentEndDate) {
      search.append("DataInicialPrevPgto", `${prevPaymentStartDate}`);
      search.append("DataFinalPrevPgto", `${prevPaymentEndDate}`);
    }

    if (faturado) {
      search.append("Faturado", faturado);
    }

    search.append("PageNumber", `${page.page}`);
    search.append("PageSize", page.rowsPerPage.toPrecision());

    return httpClientRequest.get(`/NotaFiscal/Fornecedor?${search.toString()}`);
  }

  static getStatus() {
    return httpClientRequest.get(`/NotaFiscalStatus`);
  }

  static getStatusProgress(idNota: string) {
    const search = new URLSearchParams();
    search.append("NotaFiscalId", idNota);

    return httpClientRequest.get(
      `/AndamentoStatusNotaFiscal?${search.toString()}`
    );
  }

  static created(invoices: iInvoices) {
    return httpClientRequest.post(
      `/NotaFiscal`,
      Ivoices.adapterToBody(invoices)
    );
  }

  static createdStatus(body: any) {
    return httpClientRequest.post(`/AndamentoStatusNotaFiscal`, body);
  }

  static updated(invoices: iInvoices) {
    return httpClientRequest.put(
      `/NotaFiscal/${invoices.id}`,
      Ivoices.adapterToBody(invoices)
    );
  }

  static deleted(id: string | null | undefined) {
    return httpClientRequest.delete(`/NotaFiscal/${id}`);
  }

  static createdRelationWithRequest(body: any) {
    return httpClientRequest.post(`/NotaFiscalPedido`, {
      notaFiscalId: body?.notaFiscalId,
      pedidoId: body?.pedidoId,
    });
  }

  static deletedRelationWithRequest(id: string | null | undefined) {
    return httpClientRequest.delete(`/NotaFiscalPedido/${id}`);
  }

  static importInvoice(body: any, id: string) {
    return httpClientImportInvoice.post(
      `/NotaFiscal/ImportXML?pedidoId=${id}`,
      body
    );
  }
}

export default invoicesConsumer;
