/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Divider,
  FormControl,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { addHours, format } from "date-fns";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import Empty from "src/components/Empty";
import Layout from "src/components/Layout";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import useRoutines from "src/hooks/useRoutine";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { palette } from "src/theme";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const Routine: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    page,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    dataRequests,
    getRequestsWithoutShoppingProccess,
    requestSearch,
    setRequestSearch,
    shoppingProccessSelected,
    setShoppingProccessSelected,
    requestSelected,
    setRequestSelected,
    handleLink,
    setLoading,
  } = useRoutines();

  const {
    numberSearch,
    setNumberSearch,
    getShoppingProcess,
    shoppingProcess,
    loading: loadingShoppingProcess,
    page: pageShoppingProcess,
    handleChangePage: handleChangePageShoppingProcess,
    handleChangeRowsPerPage: handleChangeRowsPerPageShoppingProcess,
  } = useShoppingProcess();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: translations[param]["routines"],
    },
    {
      name: translations[param]["from_to_orders"],
    },
  ];

  const headerShoppingProccess = [
    {
      label: "",
    },
    {
      label: translations[param]["code"],
    },
    {
      label: translations[param]["description"],
    },
    {
      label: translations[param]["date"],
    },
  ];

  const headerRequest = [
    {
      label: "",
    },
    {
      label: translations[param]["number"],
    },
    {
      label: translations[param]["supplier"],
    },
    {
      label: translations[param]["date"],
    },
    {
      label: translations[param]["value"],
    },
  ];

  useEffect(() => {
    setLoading(true);

    getRequestsWithoutShoppingProccess();

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      setNumberSearch("");
      setRequestSearch("");
    };
  }, [page.page, page.rowsPerPage]);

  useEffect(() => {
    getShoppingProcess();
  }, [pageShoppingProcess.page, pageShoppingProcess.rowsPerPage]);

  return (
    <Layout buttons={[]} breadcrumbs={breadcrumb}>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={12} sm={6} display="flex" alignItems="center">
            <Typography
              sx={{ minWidth: "15%" }}
              variant="subtitle1"
              gutterBottom
            >
              <strong>{translations[param]["purchasing_processes"]}: </strong>
            </Typography>
            <FormControl sx={{ marginRight: "1%", minWidth: "50%" }}>
              <TextField
                size="small"
                id="shoppingProccess"
                name="shoppingProccess"
                label={`${translations[param]["search"]} ${translations[param]["purchasing_processes"]}`}
                variant="outlined"
                value={numberSearch}
                onChange={(e) => setNumberSearch(e.target.value)}
              />
            </FormControl>
            <ButtonDefault
              label={translations[param]["search"]}
              onClick={() => getShoppingProcess()}
            />
          </Grid>
          <Grid xs={12} sm={6} display="flex" alignItems="center">
            <Typography
              sx={{ minWidth: "17%" }}
              variant="subtitle1"
              gutterBottom
            >
              <strong>{translations[param]["request"]}: </strong>
            </Typography>
            <FormControl sx={{ marginX: "2%", minWidth: "50%" }}>
              <TextField
                size="small"
                id="requests"
                name="requests"
                label={`${translations[param]["search"]} ${translations[param]["code"]}`}
                variant="outlined"
                value={requestSearch}
                onChange={(e) => setRequestSearch(e.target.value)}
              />
            </FormControl>
            <ButtonDefault
              label={translations[param]["search"]}
              onClick={() => getRequestsWithoutShoppingProccess()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider
        style={{ backgroundColor: palette.primary.main, marginTop: 4 }}
      />

      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        display="flex"
        justifyContent="space-around"
        alignItems="flex-start"
        sx={{ marginTop: "5%" }}
      >
        <Grid xs={12} sm={5}>
          {loadingShoppingProcess ? (
            <CircularLoading />
          ) : shoppingProcess.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={headerShoppingProccess}
              page={pageShoppingProcess}
              handleChangePage={handleChangePageShoppingProcess}
              handleChangeRowsPerPage={handleChangeRowsPerPageShoppingProcess}
            >
              {shoppingProcess
                ?.sort((a: any, b: any) =>
                  a.number.localeCompare(b.number, undefined, {
                    numeric: true,
                  })
                )
                ?.map((item: any) => (
                  <TableRow
                    hover
                    key={item.id}
                    selected={item?.id === shoppingProccessSelected?.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={item?.id === shoppingProccessSelected?.id}
                        onClick={() => {
                          if (item?.id === shoppingProccessSelected?.id) {
                            return setShoppingProccessSelected(null);
                          }

                          setShoppingProccessSelected(item);
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item?.number}
                    </TableCell>
                    <TableCell>{item?.description}</TableCell>
                    <TableCell>
                      {item?.registerDate
                        ? format(
                            addHours(new Date(item?.registerDate), 3),
                            "dd/MM/yyyy"
                          )
                        : "- "}
                    </TableCell>
                  </TableRow>
                ))}
            </TableComponent>
          )}
        </Grid>

        <Grid
          xs={12}
          sm={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ marginY: 2 }}
        >
          <ButtonSalveForm
            label={translations[param]["link"]}
            disabled={
              shoppingProccessSelected === null || requestSelected.length === 0
            }
            onClick={handleLink}
          />
        </Grid>

        <Grid xs={12} sm={5}>
          {loading ? (
            <CircularLoading />
          ) : dataRequests.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={headerRequest}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {dataRequests
                ?.sort((a: any, b: any) =>
                  a.numero.localeCompare(b.numero, undefined, {
                    numeric: true,
                  })
                )
                ?.map((item: any) => (
                  <TableRow
                    hover
                    key={item.id}
                    selected={requestSelected.includes(item)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        size="small"
                        onClick={() =>
                          setRequestSelected([...requestSelected, item])
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item.numero}
                    </TableCell>
                    <TableCell onClick={() => {}}>
                      {item?.fornecedor?.razaoSocial ?? ""}
                    </TableCell>
                    <TableCell onClick={() => {}}>
                      {format(
                        addHours(new Date(item?.dataEmissao), 3),
                        "dd/MM/yyyy"
                      )}
                    </TableCell>
                    <TableCell onClick={() => {}}>
                      {item?.valor?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableComponent>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Routine;
