/* eslint-disable react-hooks/exhaustive-deps */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { iTableComponent } from "src/interfaces/tableComponent";
import { iCompanyPage } from "src/interfaces/company";
import useTable from "src/hooks/useTable";
import { Box, Checkbox, IconButton, TableSortLabel } from "@mui/material";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { theme } from "src/theme";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export default function TableComponent({
  header,
  children,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  funtionToOrder,
  isPagination = true,
  isSelectAll = false,
  callSelectAll,
}: {
  header: Array<iTableComponent>;
  children: any;
  page: iCompanyPage;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  funtionToOrder?: (data: {
    id: string;
    order: string;
    orderBackend?: number;
  }) => void;
  isPagination?: boolean;
  isSelectAll?: boolean;
  callSelectAll?: (e: any) => void;
}) {
  const { handleRequestSort, order, fieldOrder, setFieldOrder } = useTable();

  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <TableHead>
            <TableRow>
              {header.map((item, _) => (
                <TableCell key={item?.id}>
                  {!item?.hiddenSortedList ? (
                    <TableSortLabel
                      active={item?.id === fieldOrder?.id ? true : false}
                      direction={item?.id === fieldOrder?.id ? order : "desc"}
                      onClick={() => {
                        setFieldOrder(item);
                        if (item?.id === fieldOrder?.id) {
                          handleRequestSort(order === "asc" ? "desc" : "asc");
                        } else {
                          handleRequestSort("asc");
                        }
                        if (funtionToOrder)
                          funtionToOrder({
                            id: item?.id ?? "",
                            order: order,
                            orderBackend: item?.order,
                          });
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <>{item.label}</>
                  )}
                </TableCell>
              ))}
              {isSelectAll && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    size="small"
                    sx={{ marginRight: 1.5 }}
                    onChange={(e) => {
                      if (callSelectAll) callSelectAll(e);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {isPagination === true ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={page.total}
            rowsPerPage={page.rowsPerPage}
            page={page.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translations[param]["label_rows_per_page"]}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
