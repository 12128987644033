/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import Layout from "src/components/Layout";
import useAttributesProduct from "src/hooks/useAttributesProduct";
import CircularLoading from "src/components/Loading";
import {
  Card,
  FormLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  TableRow,
  TableCell,
} from "@mui/material";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ButtonClosedForm,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";

const AttributesProduct: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    page,
    setPage,
    pageDataType,
    setPageDataType,
    loading,
    attributeSelect,
    setAttributeSelect,
    get,
    getDataType,
    attributes,
    attributesDataType,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    post,
    put,
    deleted,
  } = useAttributesProduct();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["settings"],
    },
    {
      name: `${translations[param]["attributes"]} ${translations[param]["product"]}`,
    },
  ];

  const header = [
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: false,
    },
    {
      id: "descriptionDetail",
      label: `${translations[param]["description"]} Detalhada`,
      isOrder: false,
    },
    {
      id: "type",
      label: translations[param]["type"],
      isOrder: false,
    },
  ];

  useEffect(() => {
    get();
  }, [page.page]);

  useEffect(() => {
    getDataType();
  }, [pageDataType.page]);

  if (loading) {
    return (
      <Layout buttons={[]} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout
      buttons={[]}
      breadcrumbs={breadcrumb}
      callMenu={() => {
        setPage({
          ...page,
          page: 0,
          total: 0,
        });
        setPageDataType({
          ...page,
          page: 0,
          total: 0,
        });
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (attributeSelect?.id) {
            put();
          } else {
            post();
          }
        }}
      >
        <Card sx={{ padding: 2, width: "100%" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormLabel>{translations[param]["description"]}:</FormLabel>
              <TextField
                size="small"
                id="description"
                name="description"
                sx={{ width: "99%" }}
                label={translations[param]["description"]}
                variant="outlined"
                required
                fullWidth
                value={attributeSelect?.descricao || ""}
                onChange={(e: any) =>
                  setAttributeSelect({
                    ...attributeSelect,
                    descricao: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <FormLabel>{translations[param]["type"]}:</FormLabel>
              <Select
                fullWidth
                labelId="show_select_network"
                id="show_select_network"
                value={attributeSelect?.atributosDadosTipoId || "select"}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setAttributeSelect({
                    ...attributeSelect,
                    atributosDadosTipoId: event.target.value,
                  })
                }
              >
                <MenuItem value="select" disabled>
                  {translations[param]["select"]}:
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {attributesDataType.map((value: any) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.descricao}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>
              {translations[param]["description"]} Detalhada:
            </FormLabel>

            <TextField
              size="small"
              id="descricaoDetalhada"
              name="descricaoDetalhada"
              label={`${translations[param]["description"]} Detalhada`}
              variant="outlined"
              required
              fullWidth
              multiline
              rows={2}
              value={attributeSelect?.descricaoDetalhada || ""}
              onChange={(e: any) =>
                setAttributeSelect({
                  ...attributeSelect,
                  descricaoDetalhada: e.target.value,
                })
              }
            />
          </Grid>
        </Card>

        <Card sx={{ padding: 2, width: "100%", marginTop: 5 }}>
          {attributes.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {attributes.map((value: any) => (
                <TableRow
                  hover
                  key={value.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelected(value)}
                  >
                    {value.descricao}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelected(value)}
                  >
                    {value.descricaoDetalhada}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleSelected(value)}
                  >
                    {value.atributosDadosTipo}
                  </TableCell>

                  <TableCell
                    onClick={() =>
                      Swal.fire({
                        title: "Atenção!",
                        text: "O atributo será deletada, deseja continuar ? ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: "Sim, deletar!",
                        cancelButtonText: "Não",
                      }).then((result) => {
                        if (result.isConfirmed) deleted(value.id);
                      })
                    }
                  >
                    <DeleteIcon color="error" />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>

        <Grid xs={12} sx={{ marginTop: 5 }}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ marginTop: "2%" }}
          >
            <ButtonClosedForm
              label="Limpar formulário"
              onClick={() => handleSelected(null)}
              disabled={loading}
            />
            {attributeSelect?.id ? (
              <ButtonSalveForm
                label="Salvar"
                type="submit"
                disabled={loading}
              />
            ) : (
              <ButtonSalveForm
                label="Incluir"
                type="submit"
                disabled={loading}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default AttributesProduct;
