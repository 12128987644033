/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import CircularLoading from "src/components/Loading";
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  Card,
  Checkbox,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Grid,
  FormControl,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  ButtonClosedForm,
  ButtonDelete,
  ButtonInclude,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import Layout from "src/components/Layout";
import TableComponent from "src/components/TableComponent";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import useShoppingProcess from "src/hooks/useShoppingProcess";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import useShoppingProcessType from "src/hooks/useShoppingProcessType";
import useCompany from "src/hooks/useCompany";
import Empty from "src/components/Empty";
import { MaskStyle } from "src/styles/globalStyle";
import { addHours, format } from "date-fns";
import useShoppingProccessStatus from "src/hooks/useShoppingProccessStatus";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import useLayout from "src/hooks/useLayout";
import useTable from "src/hooks/useTable";
import ExportToExcel from "src/components/ExportToExcel";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import useCompanyContact from "src/hooks/useCompanyContact";
import useRequest from "src/hooks/useRequest";

const ShoppingProcess: React.FC = () => {
  const { lang } = useParams();
  const router = useNavigate();
  const location = useLocation();
  const { setFieldOrder } = useTable();

  const { width, setOpenDialogSearch } = useLayout();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["purchasing_processes"],
    },
  ];

  const header = [
    {
      id: "number",
      label: `N° ${translations[param]["proccess"]}`,
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
    {
      id: "buyerAdministrator",
      label: `${translations[param]["buyer_administrator"]}`.replace(" ", "/"),
      isOrder: true,
      order: 3,
    },
    {
      id: "registerDate",
      label: translations[param]["opening"],
      isOrder: true,
      order: 4,
    },
    {
      id: "contactCompanyName",
      label: translations[param]["responsible"],
      isOrder: true,
      order: 5,
    },
    {
      id: "totalValue",
      label: translations[param]["value"],
      isOrder: true,
      order: 6,
    },
    {
      id: "status",
      label: translations[param]["status"],
      isOrder: true,
      order: 7,
    },
  ];

  const {
    page,
    getShoppingProcess,
    handleDelete,
    shoppingProcess,
    loading,
    shoppingProcesSelect,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    handleSelectedCheckBox,
    disableButtons,
    codeItemSearch,
    setCodeItemSearch,
    descriptionItemSearch,
    setDescriptionItemSearch,
    orderBy,
    setOrderBy,
    numberSearch,
    setNumberSearch,
    descriptionSearch,
    setDescriptionSearch,
    typeSearch,
    setTypeSearch,
    registerDateSearch,
    setRegisterDateSearch,
    endDateSearch,
    setEndDateSearch,
    buyerAdministratorSearch,
    setBuyerAdministratorSearch,
    statusSearch,
    setStatusSearch,
    clearFilters,
    CSVGrid,
    getShoppingProcessTotal,
  } = useShoppingProcess();

  const {
    setShoppingProcessItem,
    setShoppingProcessItemToSearch,
    setPage: setPageItem,
  } = useShoppingProcessItem();

  const { setValorTaxaTotal } = useShoppingProccessRate();

  const {
    setBuyer,
    page: pageCompany,
    setPage: setPageCompany,
    setCompanysToSearchBuyer,
    setNameSearch,
    setCnpjSearch,
    setSocialNameSearch,
    setCitySearch,
    setStateSearch,
    setGroupSearch,
  } = useCompany();

  const { shoppingProcessType, getShoppingProcessType } =
    useShoppingProcessType();

  const { getShoppingProccessStatus, shoppingProccessStatus } =
    useShoppingProccessStatus();

  const { setCompanysContactToSearch } = useCompanyContact();

  const {
    setShoppingProccessId,
    setStatusFilter,
    setProccessNumberFilter,
    setDescriptionFilter,
    setRequestNumberFilter,
    setCompanyFilter,
    setNfFilter,
    setCodeItemFilter,
    setDescriptionItemFilter,
    setStartDateFilter,
    setEndDateFilter,
    setTypeFilterDate,
    setStartDateFilterNf,
    setEndDateFilterNf,
  } = useRequest();

  useEffect(() => {
    handleSelectedCheckBox(null);
    setCompanysToSearchBuyer([]);
    getShoppingProcessTotal();
    getShoppingProccessStatus();
    getShoppingProcessType();

    setFieldOrder({
      id: "registerDate",
      label: translations[param]["opening"],
      isOrder: true,
      order: 4,
    });

    setPageItem({
      ...page,
      page: 0,
      rowsPerPage: 10,
      total: 0,
    });
  }, []);

  const executeFunctions = async () => {
    await handleSelected(null);
    getShoppingProcess();
  };

  const cleanFIlters = () => {
    setNumberSearch("");
    setCodeItemSearch("");
    setTypeSearch("");
    setDescriptionSearch("");
    setDescriptionItemSearch("");
    setRegisterDateSearch("");
    setEndDateSearch("");
    setBuyerAdministratorSearch("");
    setStatusSearch("");
  };

  useEffect(() => {
    if (!shoppingProcesSelect?.id) executeFunctions();
  }, [page.page, location, orderBy, shoppingProcesSelect]);

  const buttons = [
    <ExportToExcel
      data={CSVGrid}
      fileName="processos de compra"
      buttonName="Exportar"
    />,
    <ButtonInclude
      label={`${translations[param]["include"]}`}
      onClick={() => {
        setValorTaxaTotal(0);
        setBuyer(true);
        setNameSearch("");
        setShoppingProcessItem([]);
        setShoppingProcessItemToSearch([]);
        setCompanysContactToSearch([]);
        setCnpjSearch("");
        setSocialNameSearch("");
        setCitySearch("");
        setStateSearch("");
        setGroupSearch("");
        setPageCompany({
          ...pageCompany,
          rowsPerPage: 10,
          page: 0,
        });
        router(`/${lang}/shopping/process/register`);
      }}
      disabled={!disableButtons}
    />,
    <ButtonDelete
      label={`${translations[param]["delete"]}`}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: `${translations[param]["attention"]}!`,
          text: `${translations[param]["the"]} ${translations[param]["purchasing_processes"]} ${translations[param]["will_be_deleted_do_you_want_to_continue"]}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: `${translations[param]["yes"]}`,
          cancelButtonText: `${translations[param]["no"]}`,
        }).then((result) => {
          if (result.isConfirmed) handleDelete(shoppingProcesSelect!);
        })
      }
    />,
    <Button
      onClick={() => setOpenDialogSearch(true)}
      variant="contained"
      color="primary"
      size="small"
      startIcon={<SearchIcon color="secondary" />}
    >
      Filtros Adicionais
    </Button>,
  ];

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb} callMenu={cleanFIlters}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>N° {translations[param]["proccess"]}:</FormLabel>
            <TextField
              size="small"
              id="process"
              name="process"
              label={translations[param]["proccess"]}
              variant="outlined"
              value={numberSearch}
              onChange={(e) => setNumberSearch(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ width: "20%" }}>
              {translations[param]["item_code"]}:
            </FormLabel>
            <TextField
              size="small"
              id="codeItem"
              name="codeItem"
              label={translations[param]["item_code"]}
              variant="outlined"
              value={codeItemSearch}
              onChange={(e) => setCodeItemSearch(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ minWidth: "10%" }}>
              {translations[param]["type"]}:
            </FormLabel>
            <FormControl fullWidth>
              <Select
                labelId="show_select_type"
                id="show_select_type"
                value={typeSearch}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setTypeSearch(event.target.value as string)
                }
              >
                <MenuItem value="select" disabled>
                  {width <= 1450
                    ? `${translations[param]["type"]}-${translations[param]["select"]}`
                    : translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {shoppingProcessType?.map((item) => (
                  <MenuItem key={item.id} value={`${item.id}`}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ width: "20%" }}>
              {translations[param]["description"]}:
            </FormLabel>
            <TextField
              size="small"
              id="description"
              name="description"
              label={translations[param]["description"]}
              variant="outlined"
              value={descriptionSearch}
              onChange={(e) => setDescriptionSearch(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ width: "20%" }}>
              {translations[param]["item_description"]}:
            </FormLabel>
            <TextField
              size="small"
              id="descriptionItem"
              name="descriptionItem"
              label={translations[param]["item_description"]}
              variant="outlined"
              value={descriptionItemSearch}
              onChange={(e) => setDescriptionItemSearch(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{
              paddingLeft: "1%",
              paddingTop: "1%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
            }}
          >
            <Grid>
              <FormLabel>{translations[param]["from"]}:</FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    value={registerDateSearch}
                    onChange={(e) => setRegisterDateSearch(e.target.value)}
                    min="1970-01-01"
                  />
                </MaskStyle>
              </FormControl>
            </Grid>

            <Grid>
              <FormLabel>{translations[param]["to"]}:</FormLabel>
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    value={endDateSearch}
                    onChange={(e) => setEndDateSearch(e.target.value)}
                    min="1970-01-01"
                  />
                </MaskStyle>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ width: "20%" }}>
              {translations[param]["company"]}:
            </FormLabel>
            <TextField
              size="small"
              id="company"
              name="company"
              label={translations[param]["company"]}
              variant="outlined"
              value={buyerAdministratorSearch}
              onChange={(e) => setBuyerAdministratorSearch(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel sx={{ width: "20%" }}>
              {translations[param]["status"]}:
            </FormLabel>
            <FormControl fullWidth>
              <Select
                labelId="show_select_type"
                id="show_select_status"
                value={statusSearch}
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  setStatusSearch(event.target.value as string)
                }
              >
                <MenuItem value="select" disabled>
                  {width <= 1450
                    ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                    : translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                {shoppingProccessStatus?.map((item) => (
                  <MenuItem value={`${item.id}`} key={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={12} display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
          <ButtonClosedForm
            label={translations[param]["clear_form"]}
            onClick={() => {
              handleSelected(null);
              clearFilters();
              getShoppingProcess();
            }}
          />
          <ButtonSearch
            label={translations[param]["search"]}
            onClick={() => getShoppingProcess()}
          />
        </Grid>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        <Card sx={{ padding: 2, width: "100%" }}>
          {loading ? (
            <CircularLoading />
          ) : shoppingProcess.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              funtionToOrder={(item) =>
                item?.orderBackend &&
                setOrderBy({
                  number: item?.orderBackend,
                  order: item?.order === "asc" ? true : false,
                })
              }
            >
              {shoppingProcess?.map((item) => (
                <TableRow
                  hover
                  key={item.id}
                  onClick={() => {
                    if (item?.id) {
                      setShoppingProccessId(item.id);
                    }

                    setStatusFilter("select");
                    setProccessNumberFilter("");
                    setDescriptionFilter("");
                    setRequestNumberFilter("");
                    setCompanyFilter("");
                    setNfFilter("");
                    setCodeItemFilter("");
                    setDescriptionItemFilter("");
                    setStartDateFilter("");
                    setEndDateFilter("");
                    setTypeFilterDate("emission");
                    setStartDateFilterNf("");
                    setEndDateFilterNf("");
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                  >
                    {item.number}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                    sx={{ width: "15%" }}
                  >
                    {item.description}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                    sx={{ width: "20%" }}
                  >
                    {item.buyerAdministrator}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                  >
                    {format(
                      addHours(new Date(item.registerDate), 3),
                      "dd/MM/yyyy"
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                  >
                    {item.contactCompanyName}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                  >
                    {item?.totalValue?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      router(`/${lang}/shopping/process/register`);
                    }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={item?.id === shoppingProcesSelect?.id}
                      size="small"
                      onClick={() => handleSelectedCheckBox(item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </Card>
      </Grid>
    </Layout>
  );
};

export default ShoppingProcess;
